import React from 'react';

import { Card, Spinner } from '@shopify/polaris';

export function CardLoader() {
  return (
    <Card sectioned>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Spinner />
      </div>
    </Card>
  );
}

export function FlexLoader() {
  return (
    <div className="rb-flex rb-justify-center rb-p-10">
      <Spinner size="large" />
    </div>
  );
}
