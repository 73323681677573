import React, { Dispatch, SetStateAction, useState } from 'react';

import { Modal, ProgressBar } from '@shopify/polaris';

import styles from './styles.module.css';

export interface TourProps {
  readonly pages: JSX.Element[];
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly closeCallback: () => void;
}

function Tour({ pages, setIsOpen, closeCallback }: TourProps): JSX.Element {
  const [currentPage, setCurrentPage] = useState(0);

  const onClose = () => {
    setIsOpen(false);
    closeCallback();
  };

  const secondaryClick = () =>
    currentPage === 0 ? onClose() : setCurrentPage(currentPage - 1);

  const primaryClick = () =>
    currentPage === pages.length - 1
      ? onClose()
      : setCurrentPage(currentPage + 1);

  const CurrentPage = (): JSX.Element => pages[currentPage];

  const getSecondaryButtonText = () => (currentPage ? 'Back' : 'Exit');
  const getPrimaryButtonText = () => {
    if (currentPage === pages.length - 1) return 'Exit';
    if (currentPage) return 'Next';
    return 'Start tour';
  };

  const getPercentage = (): number => ((currentPage + 1) * 100) / pages.length;

  const primaryAction = {
    content: getPrimaryButtonText(),
    onAction: primaryClick,
  };
  const secondaryActions = [
    {
      content: getSecondaryButtonText(),
      onAction: secondaryClick,
    },
  ];

  return (
    <Modal
      onClose={onClose}
      open
      title={`Page ${currentPage + 1}`}
      titleHidden
      large
      limitHeight={false}
      primaryAction={primaryAction}
      secondaryActions={secondaryActions}
      footer={<ProgressBar progress={getPercentage()} color="primary" />}
    >
      <div
        className={`rb-p-10 rb-h-100 rb-max-h-75 rb-m-auto sm:rb-max-w-770 ${styles.theme}`}
      >
        <CurrentPage />
      </div>
    </Modal>
  );
}

export default Tour;
