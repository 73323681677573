import React from 'react';

import { Spinner } from '@shopify/polaris';

import SubscriptionPlan from '../SubscriptionPlan';

interface Props {
  plans?: Plan[];
  current?: string;
  development?: boolean;
  onChoose?: Function;
}

export default function SubscriptionGrid({
  plans,
  current,
  development,
  onChoose,
}: Props): JSX.Element {
  if (!plans) {
    return (
      <div className="rb-p-10 rb-grid rb-justify-items-center">
        <Spinner />
      </div>
    );
  }

  const getCurrentPlan = () => {
    const currentPlan = plans.find(plan => plan.name === current);
    if (currentPlan) {
      return currentPlan;
    }
    return { name: '', price: 0, sessionLimit: 0 };
  };

  return (
    <div>
      <div className="rb-flex rb-flex-col rb-gap-y-[20px]">
        <SubscriptionPlan
          key="Explorer"
          name="Explorer"
          price={0}
          sessionLimit={100}
          current={!current}
          development={development}
          onChoose={onChoose}
          getCurrentPlan={getCurrentPlan}
        />
        {plans?.map(
          (plan): JSX.Element => (
            <SubscriptionPlan
              key={plan.name}
              name={plan.name}
              price={plan.price}
              sessionLimit={plan.sessionLimit}
              current={current === plan.name}
              development={development}
              onChoose={onChoose}
              getCurrentPlan={getCurrentPlan}
            />
          ),
        )}
      </div>
    </div>
  );
}

SubscriptionGrid.defaultProps = {
  plans: null,
  current: null,
  development: false,
  onChoose: null,
};
