import { Select, SelectGroup } from '@shopify/polaris';

import VariationAnalyticsTable from './VariationAnalyticsTable';
import { useAnalyticsContext } from '../../../Analytics';
import {
  useSections,
  useVariationAnalytics,
  useVariations,
} from '../../../api';
import FooterDocs from '../../FooterDocs';
import PageHeader from '../../PageHeader';
import VintageTheme from '../../VintageTheme';
import AnalyticsCard from '../AnalyticsCard';
import DateRangeSelector from '../DateRangeSelector';

type RecipeAnalyticsProps = {
  templates: SelectGroup[];
  currentTemplate: string;
  setCurrentTemplate: (newTemplate: string) => void;
  recipeData: Recipe;
};

export default function RecipeAnalytics({
  templates,
  currentTemplate,
  setCurrentTemplate,
  recipeData,
}: RecipeAnalyticsProps): JSX.Element {
  const { selectedDates } = useAnalyticsContext();
  const { sections, error: sectionsError } = useSections(currentTemplate);
  const { variations } = useVariations();
  const { data } = useVariationAnalytics(
    recipeData.id,
    selectedDates.start,
    selectedDates.end,
  );

  if (
    templates &&
    (templates.length === 0 ||
      (sections && sections.length === 0) ||
      sectionsError?.statusCode === 404)
  ) {
    return (
      <div className="rb-flex rb-flex-col rb-min-h-4/5">
        <div className="rb-mb-4">
          <PageHeader title="">
            <DateRangeSelector />
          </PageHeader>
          <div className="rb-flex rb-gap-7.5 rb-mx-11.5">
            <VintageTheme />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="rb-flex rb-flex-col rb-min-h-4/5">
      <div className="rb-mb-4">
        <PageHeader title="">
          <DateRangeSelector />
        </PageHeader>
        <div className="rb-flex rb-gap-7.5 rb-mx-11.5">
          <AnalyticsCard
            value={(data?.current.cvr || 0) * 100}
            format="percent"
            title="Conversion rate (CVR)"
            compare={(data?.previous.cvr || 0) * 100}
            loading={!data}
            learnMoreLink="https://docs.context.relevantbits.com/conversion-rate-cvr"
          />
          <AnalyticsCard
            value={data?.current.sessions}
            title="Sessions"
            compare={data?.previous.sessions}
            loading={!data}
            learnMoreLink="https://docs.context.relevantbits.com/sessions"
          />
          <AnalyticsCard
            value={data?.current.aov}
            format="money"
            title="Average order value (AOV)"
            compare={data?.previous.aov}
            loading={!data}
            learnMoreLink="https://docs.context.relevantbits.com/average-order-value-aov"
          />
        </div>
      </div>
      <div>
        <PageHeader title="Section performance">
          <Select
            label="Template"
            labelHidden
            options={templates}
            onChange={(newTemplate: string) => {
              setCurrentTemplate(newTemplate);
            }}
            value={currentTemplate}
          />
        </PageHeader>
        <div className="rb-mt-2 rb-mx-11.5">
          <VariationAnalyticsTable
            data={data}
            recipeData={recipeData}
            sections={sections}
            variations={variations}
          />
        </div>
      </div>
      <FooterDocs
        text="Measure the success of your recipe through analytics."
        link="https://docs.context.relevantbits.com/analytics"
        linkText="Learn more about analytics."
      />
    </div>
  );
}
