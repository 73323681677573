import { Button } from '@shopify/polaris';

import { useShopStatus } from '../../graph';

type RecipeTablePreviewProps = {
  recipe: Recipe;
};

export default function RecipeTablePreview({
  recipe,
}: RecipeTablePreviewProps) {
  const { storeUrl } = useShopStatus();

  const handlePreview = () =>
    window.open(
      `${storeUrl}?recipe=${encodeURIComponent(
        recipe.id,
      )}&ctx=${Math.random().toString()}`,
      '_blank',
    );

  return (
    <Button onClick={handlePreview} size="slim">
      Preview
    </Button>
  );
}
