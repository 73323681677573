import { Icon } from '@shopify/polaris';
import { ArrowDownMinor, ArrowUpMinor } from '@shopify/polaris-icons';

type PercentChangeIncidatorProps = {
  value: number;
  compare: number;
  inline?: boolean;
};

PercentChangeIndicator.defaultProps = {
  inline: false,
};

export default function PercentChangeIndicator({
  value,
  compare,
  inline,
}: PercentChangeIncidatorProps): JSX.Element {
  // Multiply by 10, round, and then divide by ten to get rounding to one
  // decimal place.
  const percentChange = Math.round((value / compare - 1) * 100 * 10) / 10;

  let arrowIcon;
  let backgroundClass = 'rb-bg-zero';
  let inlineTextClass = 'rb-text-subdued';
  if (percentChange < 0 && compare !== 0) {
    arrowIcon = (
      <Icon source={ArrowDownMinor} color={inline ? 'critical' : 'base'} />
    );
    backgroundClass = 'rb-bg-negative';
    inlineTextClass = 'rb-text-critical';
  }
  if (percentChange > 0 && compare !== 0) {
    arrowIcon = (
      <Icon source={ArrowUpMinor} color={inline ? 'success' : 'base'} />
    );
    backgroundClass = 'rb-bg-positive';
    inlineTextClass = 'rb-text-success';
  }

  if (inline) {
    return (
      <div className={`rb-flex rb-items-center ${inlineTextClass}`}>
        <div className="rb-w-[9px] rb-h-[9px]">{arrowIcon}</div>
        {percentChange === 0 || compare === 0 ? (
          <div className="rb-font-semibold">-</div>
        ) : (
          <div className="rb-text-xs rb-font-semibold">
            {Math.abs(percentChange)}%
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      className={`${
        compare === 0 ? 'rb-invisible' : ''
      } rb-flex rb-items-center ${backgroundClass} rb-rounded rb-px-1.5 rb-py-0.25 rb-text-subdued rb-font-semibold`}
    >
      {arrowIcon && <div className="rb-w-[12px] rb-h-[12px]">{arrowIcon}</div>}
      <div>{Math.abs(percentChange)}%</div>
    </div>
  );
}
