import React from 'react';

type TableRowProps = {
  children: React.ReactNode;
};

export default function TableRow({ children }: TableRowProps): JSX.Element {
  return (
    <div className="rb-flex rb-content-center rb-w-full rb-h-[52px] rb-px-[17px] rb-bg-[#ffffff] rb-border-row-border rb-border-b">
      {children}
    </div>
  );
}
