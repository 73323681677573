import React, { useState, useCallback, useEffect } from 'react';

import { Button, Card, EmptyState, Icon, Link, Select } from '@shopify/polaris';
import { DeleteMajor } from '@shopify/polaris-icons';
import { nanoid } from 'nanoid';

import { AddRuleModal } from './AddRuleModal';
import illustrationApplyFirstRule from './illustration-apply-first-rule.svg';
import styles from './styles.module.css';
import { RecipeRulesProps, RULE_TYPES } from './types';
import FooterDocs from '../FooterDocs';
import HelpText from '../HelpText';

const helpCenterBaseUrl = 'https://docs.context.relevantbits.com/';

export function createRule(typeId: string): RecipeRule {
  const ruleType = RULE_TYPES.find(({ typeId: id }) => id === typeId);

  if (!ruleType) {
    throw new Error(`invalid rule type "${typeId}"`);
  }

  return new ruleType.classType(); // eslint-disable-line new-cap
}

export default function RecipeRules({
  recipeData,
  setRecipeData,
}: RecipeRulesProps): JSX.Element {
  const [rules, setRules] = useState([] as RecipeRule[]);
  const [addRuleModalVisible, setAddRuleModalVisible] = useState(false);

  const showModal = () => setAddRuleModalVisible(true);

  const addRule = useCallback(
    (typeId: string) => {
      setAddRuleModalVisible(false);
      const rule = createRule(typeId);
      rule.id = nanoid();

      setRecipeData({
        ...recipeData,
        rules: {
          ...recipeData.rules,
          [rule.id]: { id: rule.id, typeId, ...rule.schema },
        },
      });
      setRules(r => [...r, rule]);
    },
    [setAddRuleModalVisible, recipeData, setRecipeData, setRules],
  );

  const deleteRule = useCallback(
    ruleId => {
      setRules(rulesAvailable =>
        rulesAvailable.filter(({ id }) => id !== ruleId),
      );

      setRecipeData({
        ...recipeData,
        rules: {
          ...recipeData.rules,
          [ruleId]: undefined,
        },
      });
    },
    [setRules, recipeData, setRecipeData],
  );

  useEffect(() => {
    if (recipeData) {
      setRules(
        Object.values(recipeData.rules)
          .filter(x => x)
          .map(ruleData => {
            const rule = createRule(ruleData.typeId);
            rule.id = ruleData.id;
            return rule;
          }),
      );
    }
  }, [recipeData, setRules]);

  return (
    <div className="rb-flex rb-flex-col rb-min-h-4/5">
      {rules.length !== 0 && (
        <div>
          {rules.map((rule, index) => (
            <React.Fragment key={rule.id}>
              {index ? <div className="rb-font-bold rb-my-3">AND</div> : null}
              <div className={styles.rule}>
                <div className={styles.ruleHead}>
                  <div className={styles.if}>
                    <span>{rule.name}</span>
                    <Select
                      label=""
                      options={[
                        { label: 'is', value: 'false' },
                        { label: 'is not', value: 'true' },
                      ]}
                      onChange={(selected: string) =>
                        setRecipeData({
                          ...recipeData,
                          rules: {
                            ...recipeData.rules,
                            [rule.id]: {
                              ...recipeData.rules[rule.id],
                              not: selected === 'true',
                            },
                          },
                        })
                      }
                      value={recipeData?.rules[rule.id]?.not ? 'true' : 'false'}
                    />
                    {rule.helpText ? <HelpText text={rule.helpText} /> : null}
                  </div>
                  <div
                    className={styles.deleteRule}
                    onClick={() => deleteRule(rule.id)}
                    onKeyDown={e => e.key === 'Enter' && deleteRule(rule.id)}
                    role="button"
                    tabIndex={0}
                  >
                    <Icon color="subdued" source={DeleteMajor} />
                  </div>
                </div>
                <div className={styles.ruleBody}>
                  {recipeData.rules[rule.id] &&
                    rule.render(recipeData.rules[rule.id], dataSetter =>
                      setRecipeData({
                        ...recipeData,
                        rules: {
                          ...recipeData.rules,
                          [rule.id]: dataSetter(recipeData.rules[rule.id]),
                        },
                      }),
                    )}
                </div>
                <div className={styles.ruleFooter}>
                  <Link
                    external
                    url={`${helpCenterBaseUrl}${rule.helpCenterArticle}`}
                  >
                    {`Learn more about the ${rule.name} rule`}
                  </Link>
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
      )}
      {rules.length === 0 ? (
        <EmptyState
          heading="Let’s apply your first rule(s)"
          action={{
            content: 'Create a rule',
            onAction: showModal,
          }}
          secondaryAction={{
            content: 'Learn more',
            url: 'https://docs.context.relevantbits.com/whats-a-rule',
          }}
          image={illustrationApplyFirstRule}
        >
          <p>
            Apply rules to determine when to display your content variations,
            and to whom
          </p>
        </EmptyState>
      ) : (
        <Card.Section>
          <Button onClick={showModal}>Add another rule</Button>
        </Card.Section>
      )}
      <AddRuleModal
        active={addRuleModalVisible}
        setActive={setAddRuleModalVisible}
        rules={rules}
        onSubmit={addRule}
      />
      <FooterDocs
        text="This is where the magic happens."
        link="https://docs.context.relevantbits.com/whats-a-rule"
        linkText="Learn more about rules."
      />
    </div>
  );
}
