import { useCallback, useState } from 'react';

import { useFetchJson } from '../../api';
import Switch from '../Switch';

type RecipeTableSwitchProps = {
  recipe: Recipe;
  reloadRecipes: () => Promise<void>;
};

export default function RecipeTableSwitch({
  recipe,
  reloadRecipes,
}: RecipeTableSwitchProps) {
  const [status, setStatus] = useState(recipe.status === 'enabled');
  const [isLoading, setIsLoading] = useState(false);
  const jsonFetch = useFetchJson();

  const toggleSwitchStatus = useCallback(
    async (newStatus: boolean) => {
      // Optimistic UI Update
      setStatus(prevState => !prevState);

      try {
        setIsLoading(true);
        await jsonFetch(
          'post',
          `/recipes/${encodeURIComponent(recipe.id)}/status`,
          {
            status: newStatus ? 'enabled' : 'disabled',
          },
        );
        await reloadRecipes();
      } catch (err) {
        // Revert UI Update on failure
        setStatus(prevState => !prevState);
        throw new Error("can't update recipe status");
      } finally {
        setIsLoading(false);
      }
    },
    [recipe, jsonFetch, reloadRecipes],
  );

  return (
    <div className="rb-flex rb-text-left">
      <Switch
        value={status}
        setValue={toggleSwitchStatus}
        loading={isLoading}
      />
    </div>
  );
}
