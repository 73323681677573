import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import type { ReactNode } from 'react';

import { Redirect } from '@shopify/app-bridge/actions';
import { useAppBridge } from '@shopify/app-bridge-react';
import { authenticatedFetch } from '@shopify/app-bridge-utils';
import { DataType } from '@shopify/shopify-api/dist/clients/http_client/types';
import useSWR, { SWRConfig, mutate } from 'swr';

import { AggregatedStatType } from './components/AnalyticsComponents/analytics.types';

export { mutate } from 'swr';

const API_URL = process.env.REACT_APP_API_URL as string;

type FetchFn = (
  uri: RequestInfo,
  options?: RequestInit | undefined,
) => Promise<Response>;

export type JsonFetchFn = (
  method: string,
  endpoint: string,
  payload?: Record<string, unknown>,
) => Promise<Record<string, unknown>>;

type ApiProviderValue = {
  fetch: FetchFn;
  jsonFetch: JsonFetchFn;
  reauthorize: boolean;
};

export interface ShopifyAsset {
  key: string;
  public_url: null | string;
  created_at: Date;
  updated_at: Date;
  content_type: DataType;
  size: number;
  checksum: string;
  theme_id: number;
  value: string;
}

export interface Option {
  value: string;
  label?: string;
  group?: string;
}

export interface Setting {
  id: string;
  type: string;
  label: string;
  default?: any;
  info?: string;
  options?: Option[];
  placeholder?: string;
  min?: number;
  max?: number;
  step?: number;
  unit?: string;
  limit?: number;
  accept?: string[];
  content?: string;
}

export interface Block {
  type: string;
  name: string;
  limit: number;
  settings: Setting[];
}

export interface PresetBlock {
  type: string;
}

export interface Presets {
  name: string;
  blocks: PresetBlock[];
}

export interface Schema {
  name: string;
  tag?: string;
  class?: string;
  settings: Setting[];
  blocks: Block[];
  presets: Presets;
}

export interface ApiError {
  statusCode: number;
  message: string;
}

export class ApiError extends Error {
  statusCode: number;

  constructor(statusCode: number, message: string) {
    super(message);
    this.statusCode = statusCode;
  }
}

export const ApiContext = React.createContext({
  fetch: (uri: RequestInfo, options?: RequestInit | undefined) =>
    fetch(`${API_URL}${uri}`, options),
} as ApiProviderValue);

export function userLoggedInFetch(app: any): any {
  const fetchFunction = authenticatedFetch(app);

  return async (uri: any, options: any) => {
    const response = await fetchFunction(uri, options);

    if (
      response.headers.get('X-Shopify-API-Request-Failure-Reauthorize') === '1'
    ) {
      const authUrlHeader = response.headers.get(
        'X-Shopify-API-Request-Failure-Reauthorize-Url',
      );

      const redirect = Redirect.create(app);
      redirect.dispatch(Redirect.Action.REMOTE, authUrlHeader as string);
      return { status: 403 };
    }

    return response;
  };
}

export function ApiProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const app = useAppBridge();
  // The `reauthorize` state's value is passed in the ApiProvider context value,
  // so we can display a spinner instead of rendering anything while the page is
  // being reloaded to reauthorize. (See packages/app/src/components/Layout.tsx.)
  // When this happens the browser loads our app URL directly before it redirects
  // back to the Shopify admin where the app gets embedded. On this page we also
  // want to display a spinner only, so we tap into the app object returned by the
  // Shopify App Bridge to provide a default value for our state. The
  // `hostOrigin` contains the URL of the Shopify Admin page, but it is
  // undefined when we load the App URL directly.
  const [reauthorize, setReauthorize] = useState(!app.hostOrigin);
  const fetch = userLoggedInFetch(app);

  const jsonFetch = useCallback(
    async (
      method: string,
      endpoint: RequestInfo,
      payload?: Record<string, unknown>,
    ) => {
      const url = `${API_URL}${endpoint}`;
      const response = await fetch(url, {
        method: method || 'post',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: payload && JSON.stringify(payload),
      });

      if (response.status === 403) {
        throw new ApiError(403, 'Reauthorization required');
      }

      let body: any;

      if (response.status !== 204) {
        body = await response.json();
      }

      if (response.status < 200 || response.status > 299) {
        throw new ApiError(
          response.status,
          body?.message || `can't fetch data from ${endpoint}`,
        );
      }

      return body;
    },
    [fetch],
  );

  const apiContext = useMemo(
    () => ({
      fetch,
      jsonFetch,
      reauthorize,
    }),
    [fetch, jsonFetch, reauthorize],
  );

  const swrConfig = useMemo(
    () => ({
      fetcher: (uri: RequestInfo, options?: RequestInit | undefined) =>
        jsonFetch(options?.method || 'get', uri),
      onError: (error: ApiError) => {
        if (error.statusCode === 403) {
          setReauthorize(true);
        }
      },
    }),
    [jsonFetch, setReauthorize],
  );

  return (
    <ApiContext.Provider value={apiContext}>
      <SWRConfig value={swrConfig}>{children}</SWRConfig>
    </ApiContext.Provider>
  );
}

export function useFetch(): FetchFn {
  const { fetch } = useContext(ApiContext);
  return fetch;
}

export function useFetchJson(): ApiProviderValue['jsonFetch'] {
  const { jsonFetch } = useContext(ApiContext);
  return jsonFetch;
}

type FindRecipesResponse = {
  recipes: Recipe[] | undefined;
  error: ApiError;
  reload: () => Promise<void>;
};

export function useRecipes(): FindRecipesResponse {
  const endpoint = '/recipes';
  const { data, error } = useSWR(endpoint);

  const reload = useCallback(() => mutate(endpoint), []);

  const recipes = useMemo(() => {
    if (!data || !data.items) {
      return undefined;
    }

    return (data.items as Recipe[]).sort((a, b) => a.index - b.index);
  }, [data]);

  return { recipes, error, reload };
}

type GetRecipeResponse = {
  recipe: Recipe | undefined;
  error: ApiError;
  reload: () => Promise<void>;
};

export function useRecipe(id: string): GetRecipeResponse {
  const recipeId = encodeURIComponent(id);
  const endpoint = `/recipes/${recipeId}`;
  const { data, error } = useSWR(endpoint);

  const reload = useCallback(() => mutate(endpoint), [endpoint]);

  return { recipe: data, error, reload };
}

type UseRecipeStatsType = {
  views: AggregatedStatType;
  clicks: AggregatedStatType;
  activations: AggregatedStatType;
  error: ApiError;
  loading: boolean;
  reload: () => Promise<void>;
};

export function useRecipeStats(
  id: string,
  startDate: string,
  endDate: string,
): UseRecipeStatsType {
  const recipeId = encodeURIComponent(id);
  const endpoint = `/recipes/${recipeId}/stats?startDate=${startDate}&endDate=${endDate}`;
  const { data, error } = useSWR(endpoint);

  const reload = useCallback(() => mutate(endpoint), [endpoint]);

  return {
    ...data,
    error,
    loading: !error && !data,
    reload,
  };
}

export type Variation = {
  id: string;
  name: string;
  type: string;
  sectionId: string;
  blocks: Record<string, any>;
  block_order: string[];
  settings: Record<string, any>;
  message?: string;
};

type FindVariationsResponse = {
  variations: Variation[];
  error: ApiError;
  reload: () => Promise<void>;
};

export function useVariations(): FindVariationsResponse {
  const endpoint = '/variations';
  const { data, error } = useSWR(endpoint);
  const reload = useCallback(() => mutate(endpoint), [endpoint]);
  return { variations: data?.items, error, reload };
}

type GetVariationResponse = {
  variation: Variation | undefined;
  error: ApiError;
  reload: () => Promise<void>;
};

export function useVariation(id: string): GetVariationResponse {
  const endpoint = `/variations/${encodeURIComponent(id)}`;
  const { data, error } = useSWR(endpoint);
  const reload = useCallback(() => mutate(endpoint), [endpoint]);
  return { variation: data, error, reload };
}

export type Section = {
  id: string;
  title: string;
  type: string;
  block_order: string[];
  blocks?: object;
  settings?: object;
};

type FindSectionsResponse = {
  sections: Section[] | undefined;
  error: ApiError;
  reload: () => Promise<void>;
};

export function useSections(template?: string): FindSectionsResponse {
  const endpoint = `/shopify/sections?template=${template}`;
  const { data, error } = useSWR(endpoint);
  const reload = useCallback(() => mutate(endpoint), [endpoint]);
  return { sections: data, error, reload };
}

interface Theme {
  readonly themeId: number;
}

type ThemeResponse = {
  readonly themeId: number | undefined;
  readonly error: ApiError;
  readonly reload: () => Promise<void>;
};

export function useTheme(): ThemeResponse {
  const endpoint = `/themes/main`;
  const { data, error } = useSWR<Theme | undefined>(endpoint);
  const reload = useCallback(() => mutate(endpoint), [endpoint]);
  const themeId = data?.themeId;
  return { themeId, error, reload };
}

type FindTemplatesResponse = {
  templates: ShopifyAsset[];
  error: ApiError;
  reload: () => Promise<void>;
};

export function useTemplates(): FindTemplatesResponse {
  const endpoint = '/templates';
  const { data, error } = useSWR(endpoint);
  const reload = useCallback(() => mutate(endpoint), []);
  return { templates: data?.items, error, reload };
}

type GetShopifyAssetResponse = {
  asset: Partial<ShopifyAsset> | undefined;
  error: ApiError;
  reload: () => Promise<void>;
};

export function useShopifyAsset(
  key: string,
  themeId: number | undefined,
): GetShopifyAssetResponse {
  const endpoint = `/shopify/assets/`;
  const query = `?key=${encodeURIComponent(key)}${
    themeId ? `&themeId=${themeId}` : ''
  }`;
  const { data, error } = useSWR(`${endpoint}${query}`);
  const reload = useCallback(() => mutate(endpoint), [endpoint]);
  return { asset: data, error, reload };
}

export function useShopifyAssetJSON(
  key: string,
  themeId: number | undefined,
): object | undefined {
  const { asset } = useShopifyAsset(key, themeId);
  const [result, setResult] = useState<object | undefined>(undefined);

  useEffect(() => {
    try {
      setResult(JSON.parse(asset?.value || '{}'));
    } catch (e) {
      setResult({});
    }
  }, [asset, setResult]);

  return result;
}

type UseSessionOptionResponse = {
  viewedTour?: boolean;
  viewedABTesting?: boolean;
  error: ApiError;
};

export function useOptions(): UseSessionOptionResponse {
  const { data, error } = useSWR('/session/options', {
    fallbackData: { viewedTour: true, viewedABTesting: true },
    revalidateOnMount: true,
  });
  return { ...data, error };
}

type ListPlansResponse = {
  plans: Plan[] | undefined;
  error: ApiError;
};

export function usePlans(): ListPlansResponse {
  const endpoint = '/shopify/plans';
  const { data, error } = useSWR(endpoint);

  return { plans: data, error };
}
type LimitsType = {
  sessionsUsed: number;
  sessionsAllocated: number;
  startOfCycle: string;
  endOfCycle: string;
};

type ListLimitsResponse = {
  limits: LimitsType | undefined;
  error: ApiError;
};

export function useLimits(): ListLimitsResponse {
  const endpoint = '/shopify/limits';
  const { data, error } = useSWR<LimitsType>(endpoint);

  return { limits: data, error };
}

type ListIntegrationsResponse = {
  integrations: Integration[] | undefined;
  error: ApiError;
  reload: () => Promise<void>;
};

export function useIntegrations(): ListIntegrationsResponse {
  const endpoint = '/integrations';
  const { data, error } = useSWR(endpoint);

  const reload = useCallback(() => mutate(endpoint), [endpoint]);

  return { integrations: data, error, reload };
}

type Segment = {
  label: string;
  value: string;
};

type ListSegmentsResponse = {
  segments: Segment[];
  error: ApiError;
  reload: () => Promise<void>;
};

export enum SegmentTypes {
  shopify = 'shopify',
  klaviyo = 'klaviyo',
}

export function useSegments(type: SegmentTypes): ListSegmentsResponse {
  const endpoint = `/segments/${type}`;
  const { data, error } = useSWR(endpoint);

  const reload = useCallback(() => mutate(endpoint), [endpoint]);

  return { segments: data, error, reload };
}

type Snapshot = {
  sessions: number;
  cvr: number;
  aov: number;
};

type SnapshotDataComparison = {
  current: Snapshot;
  previous: Snapshot;
};

type AnalyticsSnapshotResponse = {
  data: SnapshotDataComparison;
  error: ApiError;
  reload: () => Promise<void>;
};

export function useAnalyticsSnapshot(
  start: Date,
  end: Date,
): AnalyticsSnapshotResponse {
  const endpoint = `/analytics/snapshot?start=${start.toISOString()}&end=${end.toISOString()}`;
  const { data, error } = useSWR(endpoint);

  const reload = useCallback(() => mutate(endpoint), [endpoint]);

  return { data, error, reload };
}

export type RecipeAnalytics = Record<
  string,
  {
    sessions: number;
    aov: number;
    upt: number;
    cvr: number;
    impressions: number;
    engaged: number;
  }
>;

type RecipeAnalyticsResponse = {
  data: RecipeAnalytics;
  error: ApiError;
  reload: () => Promise<void>;
};

export function useRecipeAnalytics(
  start: Date,
  end: Date,
): RecipeAnalyticsResponse {
  const endpoint = `/analytics/recipes?start=${start.toISOString()}&end=${end.toISOString()}`;
  const { data, error } = useSWR(endpoint);

  const reload = useCallback(() => mutate(endpoint), [endpoint]);

  return { data, error, reload };
}

type VariationAnalytics = {
  sessions: number;
  aov: number;
  upt: number;
  cvr: number;
  impressions: number;
  engaged: number;
  variations: Record<
    string,
    {
      impressions: number;
      engaged: number;
      aov: number;
      upt: number;
      cvr: number;
    }
  >;
};

export type VariationAnalyticsComparison = {
  current: VariationAnalytics;
  previous: VariationAnalytics;
};

type VariationAnalyticsResponse = {
  data: VariationAnalyticsComparison;
  error: ApiError;
  reload: () => Promise<void>;
};

export function useVariationAnalytics(
  recipeId: string,
  start: Date,
  end: Date,
): VariationAnalyticsResponse {
  const id = encodeURIComponent(recipeId);
  const endpoint = `/analytics/variations?start=${start.toISOString()}&end=${end.toISOString()}&recipe=${id}`;
  const { data, error } = useSWR(endpoint);

  const reload = useCallback(() => mutate(endpoint), [endpoint]);

  return { data, error, reload };
}
