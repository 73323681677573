import React from 'react';

import { Heading } from '@shopify/polaris';

import styles from './styles.module.css';

type Props = {
  title: string;
  subtitle?: string;
  whiteBackground?: boolean;
  divider?: boolean;
  morePadding?: boolean;
  leftChildren?: React.ReactNode;
  children?: React.ReactNode;
};

export default function PageHeader({
  title,
  subtitle,
  whiteBackground,
  divider,
  morePadding,
  leftChildren,
  children,
}: Props): JSX.Element {
  let extraClasses = '';
  extraClasses += whiteBackground ? ' rb-bg-white-bg' : '';
  extraClasses += divider ? ' rb-border-b rb-border-divider' : '';
  extraClasses += morePadding ? ' rb-py-4' : ' rb-py-2';

  if (leftChildren) {
    return (
      <>
        <div
          className={`rb-flex rb-row rb-items-center rb-px-11.5 ${extraClasses}`}
        >
          <div className={styles.title}>
            <Heading element="h1">{title}</Heading>
            {subtitle && (
              <Heading element="h2">
                <div style={{ color: '#6D7175' }}>{subtitle}</div>
              </Heading>
            )}
          </div>
        </div>
        <div
          className={`rb-flex rb-row rb-items-center rb-px-11.5 ${extraClasses}`}
        >
          <div>{leftChildren}</div>
          <div className={styles.addons}>
            {React.Children.map(children, child => (
              <div className={styles.addon}>{child}</div>
            ))}
          </div>
        </div>
      </>
    );
  }

  return (
    <div
      className={`rb-flex rb-row rb-items-center rb-px-11.5 ${extraClasses}`}
    >
      <div className={styles.title}>
        <Heading element="h1">{title}</Heading>
        {subtitle && (
          <Heading element="h2">
            <div style={{ color: '#6D7175' }}>{subtitle}</div>
          </Heading>
        )}
      </div>
      <div className={styles.addons}>
        {React.Children.map(children, child => (
          <div className={styles.addon}>{child}</div>
        ))}
      </div>
    </div>
  );
}

PageHeader.defaultProps = {
  subtitle: null,
  children: null,
  whiteBackground: false,
  divider: false,
  morePadding: false,
  leftChildren: null,
};
