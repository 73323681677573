import React, { useState, useCallback } from 'react';

import { Modal } from '@shopify/polaris';

import { useFetchJson } from '../../api';

type DeleteRecipeModalProps = {
  recipe: Recipe;
  active: boolean;
  setActive: (active: boolean) => void;
  onDeleted: (error?: Error) => void;
};

export default function DeleteRecipeModal({
  recipe,
  active,
  setActive,
  onDeleted,
}: DeleteRecipeModalProps): JSX.Element {
  const jsonFetch = useFetchJson();
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(() => {
    async function submit() {
      setLoading(true);

      try {
        await jsonFetch('delete', `/recipes/${encodeURIComponent(recipe.id)}`);
        onDeleted();
      } catch (err) {
        onDeleted(err as Error);
      }

      setLoading(false);
      setActive(false);
    }

    submit();
  }, [setActive, recipe, jsonFetch, onDeleted, setLoading]);

  return (
    <Modal
      open={active}
      onClose={() => setActive(false)}
      title="Are you sure you want to delete this recipe?"
      primaryAction={{
        content: 'Delete',
        onAction: handleSubmit,
        loading,
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: () => setActive(false),
        },
      ]}
    >
      <Modal.Section>
        <div>
          Do you really want to delete this recipe? This cannot be undone.
        </div>
      </Modal.Section>
    </Modal>
  );
}
