/*
 * This is a modified version of the AccountConnection component from Shopify Polaris
 * which includes a secondary button and a heavier title.
 *
 * Here's the Polaris implementation for reference:
 * https://github.com/Shopify/polaris/blob/main/polaris-react/src/components/AccountConnection/AccountConnection.tsx
 */

import {
  Card,
  ButtonGroup,
  // Avatar,
  buttonFrom,
  ComplexAction,
} from '@shopify/polaris';

export interface AccountConnectionProps {
  title?: React.ReactNode;
  details?: React.ReactNode;
  termsOfService?: React.ReactNode;
  accountName?: string;
  // avatarUrl?: string;
  connected?: boolean;
  action?: ComplexAction;
  secondaryAction?: ComplexAction;
  errorBanner?: React.ReactNode;
  showErrorBanner?: boolean;
}

export default function AccountConnection({
  connected,
  action,
  secondaryAction,
  // We can add the avatar stuff later if we want to.
  // avatarUrl,
  accountName,
  title,
  details,
  termsOfService,
  errorBanner,
  showErrorBanner,
}: AccountConnectionProps): JSX.Element {
  // const initials = accountName
  //   ? accountName
  //       .split(/\s+/)
  //       .map(name => name[0])
  //       .join('')
  //   : undefined;

  // const avatarMarkup = connected ? (
  //   <Avatar
  //     accessibilityLabel=""
  //     name={accountName}
  //     initials={initials}
  //     source={avatarUrl}
  //   />
  // ) : null;

  const titleMarkup = title || accountName;

  const detailsMarkup = details || null;

  const termsOfServiceMarkup = termsOfService ? (
    <div>{termsOfService}</div>
  ) : null;

  const actionElement = action
    ? buttonFrom(action, { primary: !connected })
    : null;

  const secondaryActionElement = secondaryAction
    ? buttonFrom(secondaryAction, { primary: connected })
    : null;

  return (
    <Card>
      {showErrorBanner && errorBanner ? (
        <div className="rb-px-5 rb-pt-5">{errorBanner}</div>
      ) : (
        <div />
      )}
      <div className="rb-p-5">
        <div className="rb-flex rb-flex-row wb-w-full">
          {/* {avatarMarkup ? (
            <div className="rb-mr-3.6">{avatarMarkup}</div>
          ) : (
            <div />
          )} */}
          <div className="rb-mr-auto">
            <div className="rb-flex rb-flex-col rb-w-full">
              <h2 className="rb-font-semibold">{titleMarkup}</h2>
              <p className="rb-mt-2 rb-text-subdued">{detailsMarkup}</p>
            </div>
          </div>
          <div className="rb-flex rb-items-center">
            <ButtonGroup>
              {secondaryActionElement}
              {actionElement}
            </ButtonGroup>
          </div>
        </div>
        {termsOfServiceMarkup ? (
          <div className="rb-mt-5">{termsOfServiceMarkup}</div>
        ) : (
          <div />
        )}
      </div>
    </Card>
  );
}

AccountConnection.defaultProps = {
  title: '',
  details: '',
  termsOfService: '',
  accountName: '',
  // avatarUrl: '',
  connected: false,
  action: undefined,
  secondaryAction: undefined,
  errorBanner: null,
  showErrorBanner: false,
};
