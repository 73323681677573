import React from 'react';

import { Filters } from '@shopify/polaris';

type Props = {
  queryValue: string;
  queryPlaceholder?: string;
  setFilter: (filter: string) => void;
};

export default function FilterField({
  queryValue,
  queryPlaceholder,
  setFilter,
}: Props): JSX.Element {
  return (
    <Filters
      queryValue={queryValue}
      queryPlaceholder={queryPlaceholder}
      filters={[]}
      appliedFilters={[]}
      onQueryChange={setFilter}
      onQueryClear={() => setFilter('')}
      onClearAll={() => setFilter('')}
    />
  );
}

FilterField.defaultProps = {
  queryPlaceholder: null,
};
