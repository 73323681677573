import React, { useEffect, useState } from 'react';

import { useQuery, gql } from '@apollo/client';
import * as Sentry from '@sentry/react';

import { useShopifyAssetJSON, useTheme } from './api';

const FREE_ON_DEV = true;

export const SHOP_STATUS = gql`
  {
    shop {
      id
      email
      plan {
        partnerDevelopment
      }
      primaryDomain {
        url
      }
      myshopifyDomain
    }
  }
`;

export const APP_STATUS = gql`
  {
    app {
      title
      installation {
        launchUrl
        activeSubscriptions {
          id
          createdAt
          name
          status
        }
      }
    }
  }
`;

export interface Subscription {
  id: string;
  name: string;
  createdAt: string;
  status: string;
}

export interface AppContextInterface {
  loading: boolean;
  isDevelopment: boolean;
  myshopifyDomain?: string;
  shopifyEmail?: string;
  appExtensionId?: string;
  launchUrl?: string;
  subscription?: Subscription;
  storeUrl?: string;
  themeId?: number;
  themeSettings?: { current: any };
}

const defaultContext: AppContextInterface = {
  loading: true,
  isDevelopment: false,
};

const AppContext = React.createContext<AppContextInterface>(defaultContext);

interface AppProviderProps {
  children: React.ReactNode;
}

export function AppProvider({ children }: AppProviderProps) {
  const [appContext, setAppContext] =
    useState<AppContextInterface>(defaultContext);
  const { data: shopStatus } = useQuery(SHOP_STATUS);
  const { data: appStatus } = useQuery(APP_STATUS);
  const { themeId } = useTheme();
  const themeSettings = useShopifyAssetJSON(
    'config/settings_data.json',
    themeId,
  ) as { current: any } | undefined;

  useEffect(() => {
    if (shopStatus && themeId && themeSettings) {
      const isDevelopment: boolean =
        FREE_ON_DEV && shopStatus.shop.plan.partnerDevelopment;
      const subscription = appStatus?.app?.installation?.activeSubscriptions
        ?.length
        ? {
            ...appStatus.app.installation.activeSubscriptions[0],
          }
        : undefined;
      setAppContext({
        loading: false,
        isDevelopment,
        myshopifyDomain: shopStatus.shop.myshopifyDomain,
        shopifyEmail: shopStatus.shop.email,
        appExtensionId: process.env.REACT_APP_SHOPIFY_EXTENSION_ID,
        launchUrl: appStatus?.app.installation.launchUrl,
        subscription,
        storeUrl: shopStatus.shop.primaryDomain.url,
        themeId,
        themeSettings,
      });

      // Set up Sentry user info
      Sentry.setUser({
        id: shopStatus.shop.myshopifyDomain,
        username: shopStatus.shop.myshopifyDomain,
        email: shopStatus.shop.email,
      });
    }
  }, [shopStatus, appStatus, themeId, themeSettings]);

  return (
    <AppContext.Provider value={appContext}>{children}</AppContext.Provider>
  );
}

export function useShopStatus() {
  return React.useContext(AppContext);
}

export function useProductByHandle(handle: string) {
  const { data } = useQuery(
    gql`
      query getProductFromHandle($handle: String!) {
        productByHandle(handle: $handle) {
          id
          title
        }
      }
    `,
    { variables: { handle } },
  );
  return data?.productByHandle;
}

export function useCollectionByHandle(handle: string) {
  const { data } = useQuery(
    gql`
      query getCollectionFromHandle($handle: String!) {
        collectionByHandle(handle: $handle) {
          id
          title
        }
      }
    `,
    { variables: { handle } },
  );
  return data?.collectionByHandle;
}
