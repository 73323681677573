import { useState, useCallback, useEffect } from 'react';

import { Modal } from '@shopify/polaris';

import { useFetchJson, Variation } from '../../api';

type DeleteVariationModalProps = {
  variation: Variation;
  active: boolean;
  setActive: (active: boolean) => void;
  updateVariations: () => Promise<void>;
};

export default function DeleteVariationModal({
  variation,
  active,
  setActive,
  updateVariations,
}: DeleteVariationModalProps): JSX.Element {
  const jsonFetch = useFetchJson();
  const [loading, setLoading] = useState(false);

  useEffect(
    () => () => {
      setLoading(false);
    },
    [],
  );

  const handleSubmit = useCallback(() => {
    async function submit() {
      setLoading(true);

      try {
        await jsonFetch(
          'delete',
          `/variations/${encodeURIComponent(variation.id)}`,
          {}, // required for local DELETE requests
        );

        await updateVariations();
      } catch (error) {
        setLoading(false);
        throw error;
      }

      setLoading(false);
      setActive(false);
    }

    submit();
  }, [setActive, variation, jsonFetch, updateVariations, setLoading]);

  function getContent() {
    return (
      <p>
        This item will be deleted immediately, and all recipes it&apos;s linked
        to will display the default variation for the block. You can&apos;t undo
        this action.
      </p>
    );
  }

  return (
    <Modal
      open={active}
      onClose={() => setActive(false)}
      title="Are you sure you want to delete this variation?"
      primaryAction={{
        content: 'Delete',
        onAction: handleSubmit,
        loading,
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: () => setActive(false),
        },
      ]}
    >
      <Modal.Section>{getContent()}</Modal.Section>
    </Modal>
  );
}
