import { useCallback, useState } from 'react';

import { ActionList, Icon, Popover } from '@shopify/polaris';
import { MobileVerticalDotsMajor } from '@shopify/polaris-icons';

type PopoverMenuProps = {
  onRename: () => void;
  onDelete: () => void;
};

export default function PopoverMenu({
  onRename,
  onDelete,
}: PopoverMenuProps): JSX.Element {
  const [popoverActive, setPopoverActive] = useState(false);

  const togglePopoverActive = useCallback(
    () => setPopoverActive(nextPopoverActive => !nextPopoverActive),
    [],
  );

  const handleRename = useCallback(() => {
    onRename();
    setPopoverActive(false);
  }, [onRename]);

  const handleDelete = useCallback(() => {
    onDelete();
    setPopoverActive(false);
  }, [onDelete]);

  const activator = (
    <div
      onClick={togglePopoverActive}
      onKeyDown={e => e.key === 'Enter' && togglePopoverActive()}
      role="button"
      tabIndex={0}
      style={{ cursor: 'pointer' }}
    >
      <Icon source={MobileVerticalDotsMajor} color="subdued" />
    </div>
  );

  return (
    <Popover
      active={popoverActive}
      activator={activator}
      onClose={togglePopoverActive}
    >
      <ActionList
        items={[
          { content: 'Rename', onAction: handleRename },
          { content: 'Delete', destructive: true, onAction: handleDelete },
        ]}
      />
    </Popover>
  );
}
