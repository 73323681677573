import React, { useState } from 'react';

import { getConnections } from './connections';
import { useIntegrations } from '../../api';
import FilterField from '../FilterField';
import { FlexLoader } from '../utils/common-components';

export default function Integrations(): JSX.Element {
  const { integrations, reload: reloadIntegrations } = useIntegrations();
  const [filter, setFilter] = useState<string>('');

  function generateAccountConnections() {
    if (!integrations) {
      return <div />;
    }

    const connections = getConnections({ integrations, reloadIntegrations });

    return connections.map(connection => {
      if (filter && !connection[0].includes(filter.toLowerCase())) {
        return <div />;
      }
      return connection[1];
    });
  }

  if (!integrations) {
    return <FlexLoader />;
  }

  return (
    <>
      <div className="rb-flex rb-flex-row rb-items-center rb-mb-2">
        <h1 className="rb-text-4xl rb-font-semibold rb-py-1 rb-capitalize">
          Integrations
        </h1>
        <div className="rb-flex rb-flex-row rb-ml-auto">
          <FilterField
            queryValue={filter}
            setFilter={setFilter}
            queryPlaceholder="Find"
          />
        </div>
      </div>
      <div className="rb-pt-2">{generateAccountConnections()}</div>
    </>
  );
}
