import React, { useMemo, useState } from 'react';

import { ActionList, Modal, Scrollable, TextField } from '@shopify/polaris';

import { AddRuleModalProps, RULE_TYPES } from './types';

export function AddRuleModal({
  active,
  setActive,
  rules,
  onSubmit,
}: AddRuleModalProps): JSX.Element {
  const [filter, setFilter] = useState('');
  const items = useMemo(
    () =>
      RULE_TYPES.filter(
        ({ title, singleton, classType }) =>
          title.toLowerCase().includes(filter) &&
          !(singleton && rules.some(r => r instanceof classType)),
      ).map(({ title, description, typeId }) => ({
        content: title,
        helpText: description,
        onAction: () => onSubmit(typeId),
      })),
    [filter, rules, onSubmit],
  );

  return (
    <Modal
      open={active}
      onClose={() => setActive(false)}
      title="Select rule type"
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: () => setActive(false),
        },
      ]}
    >
      <Modal.Section>
        <TextField
          autoComplete="off"
          label="Search available rule types:"
          value={filter}
          onChange={setFilter}
          clearButton
          onClearButtonClick={() => setFilter('')}
        />
        <Scrollable shadow style={{ height: '300px' }}>
          <ActionList sections={[{ items }]} />
        </Scrollable>
      </Modal.Section>
    </Modal>
  );
}
