const calcPercent = (value: number, prevValue: number) => {
  let percent = 0;
  if (prevValue === 0) {
    percent = 0;
  } else {
    const diff = value - prevValue;
    percent = Math.floor((diff / prevValue) * 100);
  }
  return percent;
};

// launchURL can get retrieved from the useShopStatus hook
function getUrlToThemeEditor(
  myshopifyDomain: string | undefined,
  hostOrigin: string,
  themeId: number,
) {
  if (!themeId || !myshopifyDomain || !hostOrigin) {
    throw new Error("can't build editor url");
  }

  const shop = myshopifyDomain.split('.myshopify.com')[0];

  // check whether we're using the new admin or the old one
  if (hostOrigin !== 'https://admin.shopify.com') {
    // Using the old admin, so return the old admin's URL format
    return `https://${shop}.myshopify.com/admin/themes/${themeId}/editor`;
  }

  return `https://admin.shopify.com/store/${shop}/themes/${themeId}/editor`;
}

export function getUrlToShopFiles(
  myshopifyDomain: string | undefined,
  hostOrigin: string,
) {
  if (!myshopifyDomain || !hostOrigin) {
    throw new Error("can't build shop files url");
  }
  const shop = myshopifyDomain.split('.myshopify.com')[0];
  return `https://admin.shopify.com/store/${shop}/content/files`;
}

const replaceUrls = (input: string, storeUrl: string): string => {
  // This seems to not be the problem url. Maybe will encounter issues in the
  // future though, so leaving it here.
  // const urlPattern = /(https:\/\/fonts\.shopifycdn\.com[^"]+)"/g;
  //
  // Want to match these: //rb-context-local-spencer.myshopify.com/cdn/fonts/...
  // Regex like /(\/\/rb-context-local-spencer.myshopify.com\/cdn\/fonts\/[^"]+)"/g
  const urlPattern = new RegExp(
    `(//${storeUrl.replace('https://', '')}/cdn/fonts/[^"]+)"`,
    'g',
  );

  return input.replaceAll(urlPattern, (match, url) => {
    const encodedUrl = encodeURIComponent(url);
    return `/api/admin/proxy/fonts?storeUrl=${storeUrl}&encodedUrl=https:${encodedUrl}"`;
  });
};

export { calcPercent, getUrlToThemeEditor, replaceUrls };
