import { useState, useCallback, useMemo } from 'react';

import {
  Stack,
  Tag,
  Listbox,
  Combobox,
  Icon,
  TextContainer,
  Banner,
} from '@shopify/polaris';
import { SearchMinor } from '@shopify/polaris-icons';

import { useSegments } from '../../../../api';
import { capitalizeFirstLetter } from '../../../../common/utils';

type ComboboxOption = {
  value: string;
  label: string;
};

/* To be used with the Customer Segments rule currently. This could be
 * adapted into a more generic component if needed in the future.
 */
export function MultiAutoCombobox({
  data,
  setData,
}: RecipeRuleProps): JSX.Element {
  const { segments, error } = useSegments(data.provider);
  const [options, setOptions] = useState<ComboboxOption[]>([]);
  const [inputValue, setInputValue] = useState('');

  const customerSegments: ComboboxOption[] = useMemo(() => {
    if (!segments) {
      return [];
    }
    setOptions(segments);
    return segments;
  }, [segments]);

  const getLabelForValue = (value: string): string => {
    const [firstOption] = customerSegments.filter(
      segment => segment.value === value,
    );
    return firstOption ? firstOption.label : '';
  };

  const handleTextInputChange = useCallback(
    textInputValue => {
      setInputValue(textInputValue);
      if (textInputValue === '') {
        setOptions(customerSegments);
        return;
      }
      const filterRegex = new RegExp(textInputValue, 'i');
      const resultOptions = customerSegments.filter(option =>
        option.label.match(filterRegex),
      );
      setOptions(resultOptions);
    },
    [customerSegments],
  );

  const handleListBoxOptionSelected = useCallback(
    selected => {
      const { provider } = data;
      if (data.segments.includes(selected)) {
        setData((prevData: RecipeRuleData) => ({
          ...prevData,
          provider,
          segments: data.segments.filter(
            (option: string) => option !== selected,
          ),
        }));
      } else {
        setData((prevData: RecipeRuleData) => ({
          ...prevData,
          provider,
          segments: [...prevData.segments, selected],
        }));
      }

      handleTextInputChange('');
    },
    [data, setData, handleTextInputChange],
  );

  const handleRemoveTag = useCallback(
    tag => () => {
      const optionsArray = [...data.segments];
      optionsArray.splice(optionsArray.indexOf(tag), 1);
      setData((prevData: RecipeRuleData) => ({
        ...prevData,
        segments: optionsArray,
      }));
    },
    [data, setData],
  );

  const renderRemoveTags = (): JSX.Element[] => {
    if (!segments) {
      return [];
    }

    const tags = [];
    for (let i = 0; i < data.segments.length; i += 1) {
      const option = data.segments[i];
      if (i > 0) {
        tags.push(<span>or</span>);
      }
      tags.push(
        <Tag key={`option-${option}`} onRemove={handleRemoveTag(option)}>
          {getLabelForValue(option)}
        </Tag>,
      );
    }

    return tags;
  };

  const errorBanner = useMemo(() => {
    const provider = data.provider as string;
    return (
      <div key="errorBanner" className="rb-px-3.6 rb-py-2">
        <Banner
          title={`There was a problem with the ${capitalizeFirstLetter(
            provider,
          )} connection`}
          status="critical"
        >
          {provider === 'shopify' ? null : (
            <p>Review your integration information, and retry.</p>
          )}
        </Banner>
      </div>
    );
  }, [data]);

  const renderOptions = (): JSX.Element[] => {
    if (error) {
      return [errorBanner];
    }

    if (!segments) {
      return [
        <Listbox.Loading key="loading" accessibilityLabel="segments loading" />,
      ];
    }

    return options.map(option => {
      const { label, value } = option;

      return (
        <Listbox.Option
          key={`${value}`}
          value={value}
          selected={data.segments.includes(value)}
          accessibilityLabel={label}
        >
          {label}
        </Listbox.Option>
      );
    });
  };

  return (
    <div>
      <Combobox
        allowMultiple
        activator={
          <Combobox.TextField
            prefix={<Icon source={SearchMinor} />}
            onChange={handleTextInputChange}
            label="Search Customer segments"
            labelHidden
            value={inputValue}
            placeholder="Search Customer segments"
            autoComplete="off"
          />
        }
      >
        <Listbox onSelect={handleListBoxOptionSelected}>
          {renderOptions()}
        </Listbox>
      </Combobox>
      <TextContainer>
        <div className="rb-pt-3">
          <Stack alignment="center">{renderRemoveTags()}</Stack>
        </div>
      </TextContainer>
    </div>
  );
}
