import { Banner } from '@shopify/polaris';

import { useShopStatus } from '../../graph';

export default function ActivateContextBanner(): JSX.Element {
  const { myshopifyDomain, appExtensionId } = useShopStatus();
  // https://shopify.dev/docs/apps/online-store/theme-app-extensions/extensions-framework#app-embed-block-deep-linking-url
  const url = `https://${myshopifyDomain}/admin/themes/current/editor?context=apps&activateAppId=${appExtensionId}/context`;

  return (
    <div className="rb-px-9 rb-py-7 rb-bg-[#ffffff]">
      <Banner
        status="warning"
        title="Only one more step to go 🙌"
        action={{ content: 'Enable Context', url }}
      >
        <p>
          To ensure your content variations are swapped out in real-time,
          enabling the Context app embed on your theme is required.
        </p>
      </Banner>
    </div>
  );
}
