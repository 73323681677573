import React, { useEffect, useState, useCallback } from 'react';

import { useAppBridge } from '@shopify/app-bridge-react';
import {
  Button,
  Icon,
  UnstyledLink,
  Popover,
  ActionList,
} from '@shopify/polaris';
import { ThemeEditMajor } from '@shopify/polaris-icons';
import { useLocation } from 'react-router-dom';

import ActivateContextBanner from './ActivateContextBanner';
import SessionLimitBanner from './SessionLimitBanner';
import styles from './styles.module.css';
import { useLimits } from '../../api';
import { useShopStatus } from '../../graph';
import { getUrlToThemeEditor } from '../functions';
import WelcomeTour from '../WelcomeTour';

export default function Toolbar(): JSX.Element {
  const { loading, myshopifyDomain, themeId, themeSettings, isDevelopment } =
    useShopStatus();
  const { pathname } = useLocation();
  const { hostOrigin } = useAppBridge();
  const [editorURL, setEditorURL] = useState<any>('/');
  const [showWelcomeTour, setShowWelcomeTour] = useState(false);
  const [active, setActive] = useState(false);
  const toggleActive = useCallback(() => setActive(open => !open), []);
  const [mustActivateContext, setMustActivateContext] = useState(false);

  useEffect(
    () => () => {
      setEditorURL('/');
      setShowWelcomeTour(false);
    },
    [],
  );

  const { limits } = useLimits();
  let percentageOfSessionUsed: number = 0;
  if (limits !== undefined) {
    percentageOfSessionUsed =
      (limits.sessionsUsed / limits.sessionsAllocated) * 100;
  }

  useEffect(() => {
    const linkToEditor = async () => {
      if (!loading && themeId) {
        const editorUrl = getUrlToThemeEditor(
          myshopifyDomain,
          hostOrigin,
          themeId,
        );
        setEditorURL(editorUrl);
      }
    };

    linkToEditor();
  }, [loading, myshopifyDomain, hostOrigin, themeId]);

  useEffect(() => {
    if (!themeSettings) {
      return;
    }

    if (
      themeSettings?.current?.blocks &&
      Object.values(themeSettings.current.blocks).some(
        (block: any) => block.type.includes('context') && !block.disabled,
      )
    ) {
      setMustActivateContext(false);
      return;
    }
    setMustActivateContext(true);
  }, [themeSettings]);

  function startQuickTour() {
    setShowWelcomeTour(true);
  }
  function isInPath(currentPath: string, pathToMatch: string) {
    return (
      currentPath.endsWith(`/${pathToMatch}`) ||
      currentPath.includes(`/${pathToMatch}/`)
    );
  }

  const activator = (
    <button
      type="button"
      id="help"
      className={`${styles.toolbarItem} ${styles.help} ${
        active ? styles.help_hover : ''
      }`}
      tabIndex={0}
      onClick={toggleActive}
    >
      Help
    </button>
  );

  return (
    <>
      {mustActivateContext && <ActivateContextBanner />}
      {!isDevelopment && percentageOfSessionUsed > 90 && (
        <SessionLimitBanner limitPassed={percentageOfSessionUsed >= 100} />
      )}
      <div className={styles.toolbar}>
        <div className={styles.toolbarLeft}>
          <UnstyledLink
            url="/"
            className={`${styles.toolbarItem} ${
              pathname === '/' || isInPath(pathname, 'recipes')
                ? styles.active
                : ''
            }`}
          >
            Recipes
          </UnstyledLink>
          <UnstyledLink
            url="/settings"
            className={`${styles.toolbarItem} ${
              isInPath(pathname, 'settings') ? styles.active : ''
            }`}
          >
            Settings
          </UnstyledLink>

          <Popover
            active={active}
            onClose={toggleActive}
            activator={activator}
            autofocusTarget="first-node"
          >
            <ActionList
              actionRole="menuItem"
              items={[
                { content: 'Quick tour', onAction: startQuickTour },
                {
                  content: 'Documentation',
                  url: 'https://docs.context.relevantbits.com/',
                },
                {
                  content: 'Get help',
                  url: 'https://docs.context.relevantbits.com/support-questions',
                },
                {
                  content: 'Recipe ideas',
                  url: 'https://context.relevantbits.com/pages/cooking-with-context',
                },
                {
                  content: 'Suggest a feature',
                  url: 'https://docs.context.relevantbits.com/shoot-us-a-note',
                },
              ]}
            />
          </Popover>
        </div>
        <div className={`${styles.toolbarRight} rb-space-x-6`}>
          <div className={styles.toolbarItem}>
            <Button
              plain
              url={editorURL}
              icon={<Icon source={ThemeEditMajor} />}
            >
              Customize theme
            </Button>
          </div>
        </div>
        {showWelcomeTour && <WelcomeTour setIsOpen={setShowWelcomeTour} />}
      </div>
    </>
  );
}
