import React from 'react';

import { Link } from '@shopify/polaris';

import { ReactComponent as ErrorSVG } from './error.svg';
import { ReactComponent as PatternSVG } from './pattern.svg';
import styles from './styles.module.css';

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorWrapper extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  render(): React.ReactNode {
    const { hasError } = this.state;
    const { children } = this.props;
    if (!hasError) {
      return children;
    }
    return (
      <div className={styles.boundary}>
        <div className={styles.errorContent}>
          <div className={styles.errorMessage}>
            <PatternSVG />
            <h1 className={styles.errorHeading}>
              Something
              <br />
              went wrong.
            </h1>
            <p>
              Looks like the ingredients didn’t mix well.
              <br />
              Our technical chefs are looking into the problem.
              <br />
              Try getting a new bowl and starting again.
            </p>
            <Link url="/" removeUnderline>
              <button
                type="button"
                className={styles.button__primary}
                onClick={() => this.setState({ hasError: false })}
              >
                Start again
              </button>
            </Link>
          </div>
          <div style={{ position: 'relative', right: '10%', zIndex: 0 }}>
            <ErrorSVG />
          </div>
        </div>
      </div>
    );
  }
}

export default ErrorWrapper;
