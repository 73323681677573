import React from 'react';

interface CardProps {
  children: React.ReactNode;
  className?: string;
}

export default function Card({ children, className }: CardProps) {
  return (
    <div className={`rb-rounded-xl rb-shadow rb-p-4 rb-my-2 ${className}`}>
      {children}
    </div>
  );
}

Card.defaultProps = {
  className: undefined,
};
