import React from 'react';

import { Button } from '@shopify/polaris';

import Card from '../Card';

interface Props extends Plan {
  current?: boolean;
  development?: boolean;
  onChoose?: Function;
  getCurrentPlan: () => Plan;
}

export default function SubscriptionPlan({
  name,
  price,
  sessionLimit,
  current,
  development,
  onChoose,
  getCurrentPlan,
}: Props): JSX.Element {
  return (
    <Card
      className={current ? 'rb-bg-surface-success-subdued' : 'rb-bg-white-bg'}
    >
      <div className="rb-flex rb-items-center rb-justify-between">
        <div className="rb-flex rb-gap-x-3 rb-items-center">
          <div className="rb-w-[140px]">
            <div className="rb-text-[42px] rb-font-bold rb-text-center rb-mb-2 rb-leading-10">
              {price ? `$${price}` : 'Free'}
            </div>
            <div className="rb-text-center rb-text-subdued rb-text-sm">
              {price ? '/ month' : 'Forever'}
            </div>
          </div>
          <div>
            <h3 className="rb-text-2xl">{name}</h3>
            <p>
              {sessionLimit.toLocaleString('en-US')} personalized monthly
              sessions
            </p>
          </div>
        </div>
        <div className="rb-flex rb-justify-center">
          {current && !development && <Button disabled>Current plan</Button>}
          {!current && !development && (
            <Button
              primary
              onClick={() => {
                if (onChoose) {
                  const chosen: Plan = { name, price, sessionLimit };
                  onChoose(chosen);
                }
              }}
            >
              {getCurrentPlan().price > price ? 'Change plan' : 'Upgrade'}
            </Button>
          )}
        </div>
      </div>
    </Card>
  );
}

SubscriptionPlan.defaultProps = {
  current: false,
  development: false,
  onChoose: null,
};
