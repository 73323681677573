import React, { useContext, useEffect, useState } from 'react';

import { Spinner } from '@shopify/polaris';
import { Outlet } from 'react-router-dom';

import ErrorWrapper from './ErrorWrapper';
import Toolbar from './Toolbar';
import WelcomeTour from './WelcomeTour';
import { ApiContext, useOptions } from '../api';

export default function Layout(): JSX.Element {
  const { reauthorize } = useContext(ApiContext);
  const [isOpen, setIsOpen] = useState(false);
  const { viewedTour } = useOptions();

  useEffect(() => {
    setIsOpen(!reauthorize && viewedTour === false);
  }, [reauthorize, viewedTour]);

  return reauthorize ? (
    <div className="rb-m-8">
      <Spinner accessibilityLabel="Reloading…" size="large" />
    </div>
  ) : (
    <>
      <Toolbar />
      {isOpen && <WelcomeTour setIsOpen={setIsOpen} />}
      <ErrorWrapper>
        <Outlet />
      </ErrorWrapper>
    </>
  );
}
