import React, { useState, useEffect, useCallback } from 'react';

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  useSortable,
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Icon } from '@shopify/polaris';
import { DragHandleMinor, DeleteMajor } from '@shopify/polaris-icons';

import styles from './styles.module.css';

interface Sortable {
  id: string;
  status?: string;
}

type Column<T extends Sortable> = {
  title: string;
  key: string;
  align: string; // one of left, center, right.
  render: (item: T) => JSX.Element | string | number | undefined;
};

const ALIGN_CLASSES: Record<string, string> = {
  left: 'rb-justify-start',
  center: 'rb-justify-center',
  right: 'rb-justify-end',
};

function DeleteButton({ onClick }: { onClick: () => void }): JSX.Element {
  return (
    <div
      style={{ color: '#8C9196' }}
      onClick={e => {
        e.stopPropagation();
        onClick();
      }}
      role="button"
      tabIndex={0}
      className={styles.icons}
      onKeyDown={e => {
        e.stopPropagation();
        return e.key === 'Enter' && onClick();
      }}
    >
      <Icon source={DeleteMajor} color="subdued" />
    </div>
  );
}

export function SortableItem<T extends Sortable>({
  id,
  children,
  row,
  disabled,
  onRowClick,
}: {
  id: string;
  children: React.ReactNode;
  row: T;
  disabled: boolean;
  onRowClick: (item: T) => void;
}): JSX.Element {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      onKeyDown={e => e.key === 'Enter' && onRowClick(row)}
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      <div className={`${styles.row} ${disabled && styles.rowDisabled}`}>
        <div
          {...listeners}
          className={`${styles.icons} ${styles.draggable}`}
          tabIndex={0}
          role="button"
        >
          <Icon color="subdued" source={DragHandleMinor} />
        </div>
        {children}
      </div>
    </div>
  );
}

type Props<T extends Sortable> = {
  rows: T[];
  columns: Column<T>[];
  onRowClick: (item: T) => void;
  onDeleteClick: (item: T) => void;
  onReordered: (items: T[]) => void;
};

export default function SortableTable<T extends Sortable>({
  rows,
  columns,
  onRowClick,
  onDeleteClick,
  onReordered,
}: Props<T>): JSX.Element {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const [internalRows, setInternalRows] = useState([...rows] as T[]);

  useEffect(() => {
    setInternalRows([...rows]);
  }, [rows]);
  const handleDragEnd = useCallback(
    event => {
      const { active, over } = event;
      if (active.id !== over.id) {
        setInternalRows(items => {
          const prevIndex = items.findIndex(({ id }) => active.id === id);
          const nextIndex = items.findIndex(({ id }) => over.id === id);
          const nextItems = arrayMove(items, prevIndex, nextIndex);
          onReordered(nextItems);
          return nextItems;
        });
      }
    },
    [setInternalRows, onReordered],
  );

  return (
    <div className={styles.table}>
      <div className={styles.head}>
        {columns.map(column => (
          <div
            key={column.key}
            className={`rb-flex ${ALIGN_CLASSES[column.align]}`}
          >
            {column.title}
          </div>
        ))}
      </div>
      <div className={styles.body}>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={internalRows}
            strategy={verticalListSortingStrategy}
          >
            {internalRows.map(row => (
              <SortableItem
                key={row.id}
                id={row.id}
                row={row}
                disabled={row.status === 'disabled'}
                onRowClick={onRowClick}
              >
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                <div className={styles.rowContainer}>
                  {columns.map((column, index) =>
                    index === 0 ? (
                      <a
                        onClick={() => onRowClick(row)}
                        className={`${styles.cell} ${
                          ALIGN_CLASSES[column.align]
                        }`}
                        role="link"
                        tabIndex={-1}
                        key={`${row.id}|${column.key}`}
                      >
                        {column.render(row)}
                      </a>
                    ) : (
                      <div
                        key={`${row.id}|${column.key}`}
                        className={`${styles.cell} ${
                          ALIGN_CLASSES[column.align]
                        }`}
                      >
                        {column.render(row)}
                      </div>
                    ),
                  )}
                </div>
                <DeleteButton onClick={() => onDeleteClick(row)} />
              </SortableItem>
            ))}
          </SortableContext>
        </DndContext>
      </div>
    </div>
  );
}
