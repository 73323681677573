// AccountConnection with Klaviyo configuration
import { useState } from 'react';

import { Banner, Modal, TextField } from '@shopify/polaris';

import { useFetchJson } from '../../../api';
import AccountConnection from '../../AccountConnection';
import type { ConnectionImplementationProps } from '../types';

const learnMoreUrl: string =
  'https://help.klaviyo.com/hc/en-us/articles/115005062267-How-to-manage-your-account-s-API-keys#find-your-api-keys2';

export default function KlaviyoConnection({
  integrations,
  reloadIntegrations,
}: ConnectionImplementationProps) {
  // Account connection state
  const [primaryActionLoading, setPrimaryActionLoading] = useState(false);

  // Modal state
  const [showModal, setShowModal] = useState(false);
  const [apiKey, setApiKey] = useState<string>('');
  const [modalLoading, setModalLoading] = useState(false);
  const [showModalError, setShowModalError] = useState(false);

  const jsonFetch = useFetchJson();

  const integration = integrations.find(item => item.type === 'klaviyo');
  const connected = !!integration;
  // Show error banner if connection is not valid
  const showErrorBanner = integration && !integration.isValid;

  const handlePrimaryAction = async () => {
    if (connected) {
      // Disconnect
      setPrimaryActionLoading(true);
      try {
        await jsonFetch('DELETE', '/integrations', {
          type: 'klaviyo',
        });
        await reloadIntegrations();
      } finally {
        setPrimaryActionLoading(false);
      }
    } else {
      // Show connection modal
      setShowModal(true);
    }
  };

  const handleSecondaryAction = () => {
    if (connected) {
      // Show modal
      setShowModal(true);
    } else {
      // Open documentation in new tab
      window.open(learnMoreUrl, '_blank');
    }
  };

  const handleModalSubmit = async () => {
    // create new integration and call reloadIntegrations
    setModalLoading(true);
    try {
      await jsonFetch('PUT', '/integrations', {
        type: 'klaviyo',
        data: { apiKey },
      });
      await reloadIntegrations();
      setShowModal(false);
      setShowModalError(false);
      setApiKey('');
    } catch (error) {
      setShowModalError(true);
    }

    setModalLoading(false);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setShowModalError(false);
    setModalLoading(false);
    setApiKey('');
  };

  const errorBanner = (
    <Banner
      title="There was a problem with the Klaviyo connection"
      status="critical"
    >
      <p>Review your integration information, and retry.</p>
    </Banner>
  );

  return (
    <div data-testid="klaviyo-connection">
      <AccountConnection
        connected={connected}
        title="Klaviyo"
        details={connected ? 'Account connected' : 'No account connected'}
        termsOfService={
          connected
            ? ''
            : 'Segmentation is the fastest way to group your contacts into ultra-precise audiences that update in real time, so you can target messages based on the latest shopping behaviour, and customer data. The Klaviyo integration allows you to use your already existing customer segmentations as rules within Context.'
        }
        action={{
          content: connected ? 'Disconnect' : 'Connect',
          id: 'klaviyo-connection-action',
          onAction: handlePrimaryAction,
          loading: primaryActionLoading,
        }}
        secondaryAction={{
          content: connected ? 'Update API key' : 'Learn more',
          id: 'klaviyo-connection-secondary-action',
          onAction: handleSecondaryAction,
        }}
        errorBanner={errorBanner}
        showErrorBanner={showErrorBanner}
      />
      <Modal
        open={showModal}
        title="Connect Klaviyo to Context 🚀"
        primaryAction={{
          content: 'Connect',
          id: 'klaviyo-connection-modal-submit',
          onAction: handleModalSubmit,
          loading: modalLoading,
        }}
        onClose={handleModalClose}
      >
        {showModalError ? (
          <div className="rb-px-5 rb-pt-5">{errorBanner}</div>
        ) : (
          <div />
        )}
        <Modal.Section>
          <TextField
            label="Klaviyo API key"
            placeholder="Enter your API key"
            value={apiKey}
            onChange={(value: string) => setApiKey(value)}
            labelAction={{
              content: 'Learn more',
              onAction: () => window.open(learnMoreUrl, '_blank'),
            }}
            autoComplete="off"
            error={showModalError}
          />
        </Modal.Section>
      </Modal>
    </div>
  );
}
