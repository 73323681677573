import React from 'react';

import { FooterHelp, Link } from '@shopify/polaris';

type FooterDocsProps = {
  text: string;
  link: string;
  linkText: string;
};

export default function FooterDocs({
  text,
  link,
  linkText,
}: FooterDocsProps): JSX.Element {
  return (
    <div className="rb-flex rb-justify-start rb-mt-auto">
      <FooterHelp>
        {text}&nbsp;
        <Link external url={link}>
          {linkText}
        </Link>
      </FooterHelp>
    </div>
  );
}
