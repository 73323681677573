import React from 'react';

import { Card } from '@shopify/polaris';

type TableProps = {
  children: React.ReactNode; // content to be placed in rows
  header?: JSX.Element;
  footer?: JSX.Element;
  background?: string;
};

export default function Table({
  children,
  header,
  footer,
  background,
}: TableProps): JSX.Element {
  return (
    <Card>
      <div
        className={`rb-flex rb-flex-col rb-bg-${background} rb-rounded-[8px] rb-border rb-border-row-border rb-overflow-hidden`}
      >
        {header}
        {children}
        {footer}
      </div>
    </Card>
  );
}

Table.defaultProps = {
  header: null,
  footer: null,
  background: 'table-bg',
};
