import styles from './styles.module.css';

type Props = {
  value: boolean;
  setValue: (value: boolean) => void;
  loading?: boolean;
};

export default function Switch({
  value,
  setValue,
  loading,
}: Props): JSX.Element {
  return (
    <div
      className={`${loading && styles.loading} ${styles.switch} ${
        value && styles.active
      } rb-select-none`}
      onClick={loading ? () => {} : () => setValue(!value)}
      onKeyDown={e => e.key === 'Enter' && setValue(!value)}
      role="button"
      tabIndex={0}
    >
      <div className={styles.label}>{value ? 'ON' : 'OFF'}</div>
      <div className={`${styles.circle} rb-relative`} />
    </div>
  );
}

Switch.defaultProps = {
  loading: false,
};
