import React from 'react';

import { Tooltip, Icon } from '@shopify/polaris';
import { QuestionMarkMinor } from '@shopify/polaris-icons';

export default function HelpText({ text }: { text: string }): JSX.Element {
  return (
    <Tooltip content={text}>
      <Icon source={QuestionMarkMinor} color="base" />
    </Tooltip>
  );
}
