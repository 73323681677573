import React from 'react';

import { Banner, Link } from '@shopify/polaris';
import { useLocation } from 'react-router-dom';

interface Props {
  limitPassed?: boolean;
}

export default function SessionLimitBanner({
  limitPassed,
}: Props): JSX.Element {
  const location = useLocation();

  return (
    <div className="rb-px-9 rb-py-7 rb-bg-[#ffffff]">
      <Banner
        status={limitPassed ? 'critical' : 'warning'}
        title={
          limitPassed
            ? 'Context is no longer running your recipes 😢'
            : 'It looks like your store is growing 🚀'
        }
      >
        <span>
          {limitPassed
            ? "You've hit the session limit of your subscription plan. Upgrade now to start delivering personalized content again. "
            : "You're about to go over the session limit of your subscription plan. Upgrade to keep delivering personalized content to your visitors. "}
        </span>
        {location.pathname !== '/settings/subscriptions' && (
          <span>
            <Link url="/settings/subscriptions" monochrome>
              Upgrade plan
            </Link>
          </span>
        )}
      </Banner>
    </div>
  );
}

SessionLimitBanner.defaultProps = {
  limitPassed: false,
};
