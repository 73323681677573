import Integrations from '../components/Integrations';
import SubmenuNavigator from '../components/SubmenuNavigator';
import Subscriptions from '../components/Subscriptions';

export default function Settings(): JSX.Element {
  const options = [
    {
      label: 'Integrations',
      path: 'integrations',
      child: <Integrations />,
    },
    {
      label: 'Subscriptions',
      path: 'subscriptions',
      child: <Subscriptions />,
    },
  ];

  return <SubmenuNavigator title="Settings" options={options} />;
}
