// Make sure you include a wildcard character for the parent route in Routes.tsx

import { useCallback, useEffect, useState } from 'react';

import { OptionList } from '@shopify/polaris';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

type NavigatorOption = {
  label: string;
  path: string; // Relative path for sub route
  child: JSX.Element;
};

type SubmenuNavigatorProps = {
  title: string;
  options: NavigatorOption[]; // First item of list is index page for routing
  banner?: JSX.Element;
};

type ListOption = {
  readonly label: string;
  readonly value: string;
};

SubmenuNavigator.defaultProps = {
  banner: undefined,
};

export default function SubmenuNavigator({
  title,
  options,
  banner,
}: SubmenuNavigatorProps): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const [selected, setSelected] = useState<string[]>([]);

  function makeRoutes(): JSX.Element[] | undefined {
    return options.map((option, index) => {
      if (index === 0) {
        return <Route key="index" index element={option.child} />;
      }
      return (
        <Route key={option.path} path={option.path} element={option.child} />
      );
    });
  }

  const handleSelection = useCallback(
    (newSelected: string[]) => {
      setSelected(newSelected);
      if (newSelected[0] === '0') {
        navigate('');
        return;
      }

      // find and navigate to path of selection
      const path = options.at(parseInt(newSelected[0], 10))?.path || '';
      navigate(path);
    },
    [navigate, options],
  );

  useEffect(() => {
    // Get the last path component from the URL
    const lastPathComponent = location.pathname.split('/').at(-1);

    // Find the index of the option that matches the current route
    const index = options.findIndex(
      option => option.path === lastPathComponent,
    );

    // If no option matches the current route, select the first option
    setSelected([index === -1 ? '0' : index.toString()]);
  }, [location.pathname, options]);

  const listOptions: ListOption[] = [];
  options.forEach((option, index) =>
    listOptions.push({
      label: option.label,
      value: index.toString(),
    }),
  );

  return (
    <div className="context-subpage rb-min-h-4/5 rb-flex rb-flex-col">
      <div className="rb-flex rb-h-full">
        <div className="rb-grow-0 rb-min-w-[25%] rb-mr-2">
          <OptionList
            title={title}
            onChange={handleSelection}
            options={listOptions}
            selected={selected}
          />
          {banner}
        </div>
        <div className="rb-ml-2 rb-pt-1.5 rb-w-full">
          <Routes>{makeRoutes()}</Routes>
        </div>
      </div>
    </div>
  );
}
