/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useCallback, useState } from 'react';

import { Stack, Tag, TextField, Button } from '@shopify/polaris';

function Component({ data, setData }: RecipeRuleProps): JSX.Element {
  const [userInput, setUserInput] = useState<string | undefined>('');

  const handleAddDomain = useCallback(() => {
    setData(s => ({
      ...s,
      domains: [...s.domains, userInput],
    }));
    setUserInput('');
  }, [setData, userInput]);

  const handleRemoveDomain = useCallback(
    (domain: string) => {
      setData(s => ({
        ...s,
        domains: (s.domains as string[]).filter(curr => curr !== domain),
      }));
    },
    [setData],
  );

  const renderRemoveDomains = useCallback(() => {
    if (!data?.domains) return null;

    const domains = [];
    for (let i = 0; i < data.domains.length; i += 1) {
      const domain = data.domains[i];
      if (i > 0) {
        domains.push(<span>or</span>);
      }
      domains.push(
        <Tag key={domain} onRemove={() => handleRemoveDomain(domain)}>
          {domain}
        </Tag>,
      );
    }

    return domains;
  }, [handleRemoveDomain, data]);

  return (
    <>
      <div className="rb-flex rb-items-center rb-space-x-2 rb-pb-5">
        <span>
          your visitor arrives at your store via any of the following domains
        </span>
      </div>
      <div className="rb-flex">
        <div
          className="rb-w-full"
          onKeyDown={e => {
            if (e.key === 'Enter') handleAddDomain();
          }}
        >
          <TextField
            autoComplete="off"
            label=""
            onChange={setUserInput}
            value={userInput}
            placeholder="Domain such as 'google.com'"
            clearButton
            onClearButtonClick={() => setUserInput('')}
          />
        </div>
        <Button
          disabled={!userInput?.match(/^[a-z0-9.-]+\.[a-z]{2,}$/)}
          onClick={handleAddDomain}
        >
          Add
        </Button>
      </div>
      <div className="rb-pt-3">
        <Stack alignment="center">{renderRemoveDomains()}</Stack>
      </div>
    </>
  );
}

export default class LocationRule implements RecipeRule {
  id = '';

  name = 'Referrer';

  helpCenterArticle = 'referrer';

  schema = {
    domains: [],
  } as {
    domains: string[];
  };

  render = (
    data: RecipeRuleData,
    setData: RecipeRuleDataSetter,
  ): JSX.Element => (
    <div>
      <Component data={data} setData={setData} />
    </div>
  );
}
