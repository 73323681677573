import React, { useMemo } from 'react';

import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloProvider,
} from '@apollo/client';
import {
  Provider as AppBridgeProvider,
  ClientRouter,
  useAppBridge,
  RoutePropagator,
} from '@shopify/app-bridge-react';
import { Frame } from '@shopify/polaris';
import { useLocation, useNavigate } from 'react-router-dom';

import { AnalyticsProvider } from './Analytics';
import { userLoggedInFetch, ApiProvider } from './api';
import Routes from './components/Routes';
import { AppProvider } from './graph';

import '@shopify/polaris/build/esm/styles.css';
import './global.css';

const API_URL = process.env.REACT_APP_API_URL;
const host = new URLSearchParams(window.location.search).get('host') as string;

if (!host) {
  // We are not being loaded in from the Shopify admin, so redirect to the app
  // store instead of throwing an error!
  window.location.href = 'https://apps.shopify.com/relevantbits-context';
}

export const APP_BRIDGE_CONFIG = {
  apiKey: process.env.REACT_APP_API_KEY as string,
  // eslint-disable-next-line no-restricted-globals
  host: new URLSearchParams(location.search).get('host') as string,
  forceRedirect: true,
};
Object.freeze(APP_BRIDGE_CONFIG);

type GraphProviderProps = {
  children: React.ReactNode;
};

function GraphProvider({ children }: GraphProviderProps) {
  const app = useAppBridge();

  const client = new ApolloClient({
    link: new HttpLink({
      credentials: 'include',
      fetch: userLoggedInFetch(app),
      uri: `${API_URL}/shopify/graphql`,
      headers: {
        'Content-Type': 'application/graphql',
      },
    }),
    cache: new InMemoryCache(),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

export default function App(): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();

  const history = useMemo(
    () => ({ replace: (path: any) => navigate(path, { replace: true }) }),
    [navigate],
  );

  return (
    <Frame>
      <AppBridgeProvider config={APP_BRIDGE_CONFIG}>
        <ClientRouter
          history={{
            replace: navLocation => {
              navigate(navLocation);
            },
          }}
        />
        <RoutePropagator location={location} />
        <GraphProvider>
          <ApiProvider>
            <AppProvider>
              <AnalyticsProvider>
                <Routes location={location} history={history} />
              </AnalyticsProvider>
            </AppProvider>
          </ApiProvider>
        </GraphProvider>
      </AppBridgeProvider>
    </Frame>
  );
}
