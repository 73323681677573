import React, { Dispatch, SetStateAction } from 'react';

import ABTesting from './pages/ABTesting.mdx';
import AnyTemplate from './pages/AnyTemplate.mdx';
import EnableTheEmbed from './pages/EnableTheEmbed.mdx';
import HereToHelp from './pages/HereToHelp.mdx';
import IngredientsForSuccess from './pages/IngredientsForSuccess.mdx';
import ItAutomagicallyWorks from './pages/ItAutomagicallyWorks.mdx';
import MeasureYourSuccess from './pages/MeasureYourSuccess.mdx';
import NameYourRecipe from './pages/NameYourRecipe.mdx';
import RulesAreTheMuscle from './pages/RulesAreTheMuscle.mdx';
import TakeATour from './pages/TakeATour.mdx';
import TheRightContent from './pages/TheRightContent.mdx';
import YourFirstRecipe from './pages/YourFirstRecipe.mdx';
import { useFetchJson } from '../../api';
import Tour from '../Tour';

interface WelcomeTourProps {
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
}

function WelcomeTour({ setIsOpen }: WelcomeTourProps): JSX.Element | null {
  const jsonFetch = useFetchJson();
  const onCloseCallback = async () => {
    await jsonFetch('post', '/session/options', { viewedTour: true });
  };

  const pages: JSX.Element[] = [
    <TakeATour />,
    <TheRightContent />,
    <EnableTheEmbed />,
    <ItAutomagicallyWorks />,
    <YourFirstRecipe />,
    <NameYourRecipe />,
    <IngredientsForSuccess />,
    <AnyTemplate />,
    <ABTesting />,
    <RulesAreTheMuscle />,
    <MeasureYourSuccess />,
    <HereToHelp />,
  ];
  return (
    <Tour pages={pages} setIsOpen={setIsOpen} closeCallback={onCloseCallback} />
  );
}

export default WelcomeTour;
