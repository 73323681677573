import { useCallback, useEffect, useState } from 'react';

import { DatePicker, Icon } from '@shopify/polaris';
import { SelectMinor } from '@shopify/polaris-icons';

import { useAnalyticsContext } from '../../Analytics';

export default function DateRangeSelector(): JSX.Element {
  const [showing, setShowing] = useState(false);

  const width = 'rb-w-80';
  const bg = 'rb-bg-white-bg';
  const border = 'rb-border-solid rb-border-date-border';

  const buttonCN = `
  ${width}
  ${bg}
  ${border}
  hover:rb-bg-hover-bg 
  rb-text-left 
  rb-py-1.5 
  rb-px-3 
  rb-rounded-[4px]
  rb-border 
  rb-font-normal 
  rb-leading-20
  rb-flex
  rb-flex-row
  rb-justify-between`;

  const pickerCN = `
  ${width}
  ${bg}
  ${border}
  rb-p-2.5 
  rb-absolute 
  rb-top-8 
  rb-right-0 
  rb-z-50 
  rb-border-x 
  rb-border-b 
  rb-rounded-b-[4px] 
  ${showing ? 'rb-block' : 'rb-hidden'}`;

  const { selectedDates, setSelectedDates } = useAnalyticsContext();
  const [selectionBuffer, setSelectionBuffer] = useState(selectedDates);
  const [{ month, year }, setDate] = useState({
    month: selectedDates.end.getMonth(),
    year: selectedDates.end.getFullYear(),
  });

  const handleMonthChange = useCallback(
    (newMonth: number, newYear: number) =>
      setDate({ month: newMonth, year: newYear }),
    [],
  );

  useEffect(() => {
    if (selectionBuffer.start !== selectionBuffer.end) {
      setShowing(false);
      setSelectedDates(selectionBuffer);
    }
  }, [selectionBuffer, setSelectedDates]);

  return (
    <div className="rb-relative rb-inline-block" id="ctx-dateDropDown">
      <button
        type="button"
        id="ctx-dateDropDownBtn"
        className={buttonCN}
        onClick={() => {
          setShowing(!showing);
        }}
      >
        <span className="rb-pointer-events-none">
          {selectedDates.start.toDateString()} -{' '}
          {selectedDates.end.toDateString()}
        </span>
        <div className="rb-pointer-events-none rb-h-[20px] rb-flex rb-items-center">
          <Icon source={SelectMinor} />
        </div>
      </button>
      <div className={pickerCN} id="ctx-dateDropDownContent">
        <DatePicker
          month={month}
          year={year}
          onChange={setSelectionBuffer}
          onMonthChange={handleMonthChange}
          selected={selectionBuffer}
          allowRange
          disableDatesBefore={new Date('Wed Nov 01 2023')} // We do not want users making queries for data that don't exist.
          disableDatesAfter={new Date(Date.now())}
        />
      </div>
    </div>
  );
}
