import { Card, EmptyState } from '@shopify/polaris';

import illustrationVintageTheme from './illustration-vintage-theme.svg';

const LEARN_MORE_URL = 'https://docs.context.relevantbits.com/theme-support';
const NEED_HELP_URL = 'https://docs.context.relevantbits.com/support-questions';

export default function VintageTheme(): JSX.Element {
  return (
    <div className="rb-min-w-full rb-flex-grow">
      <Card sectioned>
        <EmptyState
          heading="Context only works with Online Store 2.0 themes"
          action={{ content: 'Learn more', url: LEARN_MORE_URL }}
          secondaryAction={{ content: 'Need help?', url: NEED_HELP_URL }}
          image={illustrationVintageTheme}
        >
          <p>It appears you&apos;re running a vintage theme.</p>
        </EmptyState>
      </Card>
    </div>
  );
}
