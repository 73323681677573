import AnalyticsCard from './AnalyticsCard';
import DateRangeSelector from './DateRangeSelector';
import { useAnalyticsContext } from '../../Analytics';
import { useAnalyticsSnapshot } from '../../api';
import PageHeader from '../PageHeader';

export default function Snapshot(): JSX.Element {
  const { selectedDates } = useAnalyticsContext();
  const { data } = useAnalyticsSnapshot(selectedDates.start, selectedDates.end);

  return (
    <div className="rb-mb-4">
      <PageHeader title="Snapshot">
        <DateRangeSelector />
      </PageHeader>
      <div className="rb-flex rb-gap-7.5 rb-mx-11.5">
        <AnalyticsCard
          value={data ? data.current.cvr * 100 : 0}
          format="percent"
          title="Conversion rate (CVR)"
          compare={data ? data.previous.cvr * 100 : 0}
          loading={!data}
          learnMoreLink="https://docs.context.relevantbits.com/conversion-rate-cvr"
        />
        <AnalyticsCard
          value={data ? data.current.sessions : 0}
          title="Sessions"
          compare={data ? data.previous.sessions : 0}
          loading={!data}
          learnMoreLink="https://docs.context.relevantbits.com/sessions"
        />
        <AnalyticsCard
          value={data ? data.current.aov : 0}
          format="money"
          title="Average order value (AOV)"
          compare={data ? data.previous.aov : 0}
          loading={!data}
          learnMoreLink="https://docs.context.relevantbits.com/average-order-value-aov"
        />
      </div>
    </div>
  );
}
