import { useMemo } from 'react';

import { Link, SkeletonDisplayText } from '@shopify/polaris';

import PercentChangeIndicator from './PercentChangeIndicator';

type AnalyticsCardProps = {
  value: number;
  format?: 'percent' | 'money';
  title: string;
  compare?: number;
  learnMoreLink?: string;
  loading?: boolean;
};

AnalyticsCard.defaultProps = {
  format: undefined,
  compare: undefined,
  learnMoreLink: undefined,
  loading: false,
};

export default function AnalyticsCard({
  value,
  format,
  title,
  compare,
  learnMoreLink,
  loading,
}: AnalyticsCardProps): JSX.Element {
  const formattedValue = useMemo(() => {
    if (loading) {
      return <SkeletonDisplayText size="medium" />;
    }

    const roundedValue = Math.round(10 ** 2 * value) / 10 ** 2;
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    if (format === 'percent') {
      return `${roundedValue}%`;
    }
    if (format === 'money') {
      return formatter.format(roundedValue);
    }
    return roundedValue;
  }, [value, format, loading]);

  return (
    <div className="rb-bg-white-bg rb-rounded-xl rb-shadow rb-p-3.6 rb-my-2 rb-flex-1">
      <div className="rb-flex rb-justify-end">
        <PercentChangeIndicator value={value} compare={compare || 0} />
      </div>
      <div className="rb-text-[4.2rem] rb-leading-none rb-font-semibold rb-py-0.25">
        {formattedValue}
      </div>
      <div className="rb-text-lg rb-leading-normal rb-font-semibold rb-text-subdued">
        {title}
      </div>
      <div>
        <Link url={learnMoreLink}>Learn more</Link>
      </div>
    </div>
  );
}
