import React, { useCallback, useEffect, useState } from 'react';

import {
  Card,
  EmptyState,
  Banner,
  ContextualSaveBar,
  Link,
} from '@shopify/polaris';
import { useNavigate } from 'react-router-dom';
import { mutate } from 'swr';

import illustrationCustomerJourney from './illustration-customer-journey.svg';
import RecipeTablePreview from './RecipeTablePreview';
import RecipeTableSwitch from './RecipeTableSwitch';
import { useAnalyticsContext } from '../../Analytics';
import {
  useRecipes,
  useFetchJson,
  RecipeAnalytics,
  useRecipeAnalytics,
} from '../../api';
import DeleteRecipeModal from '../DeleteRecipeModal';
import SortableTable from '../SortableTable';
import { CardLoader } from '../utils/common-components';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const COLUMNS = (
  analytics: RecipeAnalytics,
  reloadRecipes: () => Promise<void>,
) => [
  {
    title: 'Personalized Recipes',
    key: 'recipe',
    align: 'left',
    render: (recipe: Recipe) => recipe.name,
  },
  {
    title: 'Sessions',
    key: 'sessions',
    align: 'right',
    render: (recipe: Recipe) => analytics[recipe.id]?.sessions || 0,
  },
  {
    title: 'Impressions',
    key: 'impressions',
    align: 'right',
    render: (recipe: Recipe) => analytics[recipe.id]?.impressions || 0,
  },
  {
    title: 'Engaged',
    key: 'engaged',
    align: 'right',
    render: (recipe: Recipe) =>
      `${
        Math.round(10 ** 4 * (analytics[recipe.id]?.engaged || 0)) / 10 ** 2
      }%`,
  },
  {
    title: 'UPT',
    key: 'upt',
    align: 'center',
    render: (recipe: Recipe) => analytics[recipe.id]?.upt || 0,
  },
  {
    title: 'AOV',
    key: 'aov',
    align: 'center',
    render: (recipe: Recipe) =>
      formatter.format(analytics[recipe.id]?.aov || 0),
  },
  {
    title: 'CVR',
    key: 'cvr',
    align: 'center',
    render: (recipe: Recipe) =>
      `${Math.round(10 ** 4 * (analytics[recipe.id]?.cvr || 0)) / 10 ** 2}%`,
  },
  {
    title: '',
    key: 'preview',
    align: 'center',
    render: (recipe: Recipe) => <RecipeTablePreview recipe={recipe} />,
  },
  {
    title: 'Status',
    key: 'status',
    align: 'center',
    render: (recipe: Recipe) => (
      <RecipeTableSwitch recipe={recipe} reloadRecipes={reloadRecipes} />
    ),
  },
];

export default function RecipeList({
  filter,
  createRecipe,
}: {
  filter: string;
  createRecipe: () => void;
}): JSX.Element {
  const navigate = useNavigate();
  const { recipes, error, reload: reloadRecipeData } = useRecipes();
  const { selectedDates } = useAnalyticsContext();
  const { data: analyticsData } = useRecipeAnalytics(
    selectedDates.start,
    selectedDates.end,
  );
  const [recipeToDelete, setRecipeToDelete] = useState(
    undefined as Recipe | undefined,
  );
  const [deleteRecipeModalVisible, setDeleteRecipeModalVisible] =
    useState(false);
  const [reorderedRecipes, setReorderedRecipes] = useState(recipes || []);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [saveRecipeOrderLoading, setSaveRecipeOrderLoading] = useState(false);
  const jsonFetch = useFetchJson();
  useEffect(() => {
    setIsLoading(!recipes || !analyticsData);
    setReorderedRecipes(recipes || []);
  }, [recipes, analyticsData]);

  const emptyState = (!recipes || recipes.length === 0) && (
    <div className="rb-py-11.5">
      <EmptyState
        heading="Recipes are customer segments"
        action={{
          content: 'Create recipe',
          onAction: createRecipe,
        }}
        secondaryAction={{
          content: 'Learn more',
          url: 'https://docs.context.relevantbits.com/whats-a-recipe',
        }}
        image={illustrationCustomerJourney}
      >
        <p>
          <strong>Looking for inspiration?</strong> Explore{' '}
          <Link url="https://context.relevantbits.com/pages/cooking-with-context">
            Cooking with Context
          </Link>{' '}
          for some great personalization ideas!
        </p>
      </EmptyState>
    </div>
  );

  const reorderRecipes = useCallback(
    (recipesReordered: Recipe[]) => {
      setTimeout(() => {
        setReorderedRecipes(recipesReordered);
        setHasUnsavedChanges(true);
      });
    },
    [setReorderedRecipes, setHasUnsavedChanges],
  );

  const saveRecipeOrder = useCallback(() => {
    async function submit() {
      setSaveRecipeOrderLoading(true);

      try {
        await jsonFetch('post', '/recipes/reorder', {
          ids: reorderedRecipes.map(recipe => recipe.id),
        });
        setHasUnsavedChanges(false);
        mutate('/recipes');
      } catch (err) {
        throw new Error("can't save new recipe order");
      }

      setSaveRecipeOrderLoading(false);
    }

    submit();
  }, [jsonFetch, reorderedRecipes]);

  const [filteredRecipes, setFilteredRecipes] = useState(recipes || []);

  useEffect(() => {
    if (!recipes) {
      return;
    }

    setFilteredRecipes(
      recipes.filter(
        r => !filter || r.name.toLowerCase().includes(filter.toLowerCase()),
      ) || [],
    );
  }, [recipes, filter]);

  return isLoading ? (
    <CardLoader />
  ) : (
    <div>
      <Card>
        {error && (
          <Banner
            title="Server error."
            action={{ content: 'Reload' }}
            status="critical"
          >
            <p>There was an error while loading data from the server.</p>
          </Banner>
        )}
        {emptyState}
        {recipes && recipes.length > 0 && analyticsData && (
          <SortableTable
            columns={COLUMNS(analyticsData, reloadRecipeData)}
            rows={filteredRecipes}
            onRowClick={(recipe: Recipe) =>
              navigate(`/recipes/${encodeURIComponent(recipe.id)}`)
            }
            onDeleteClick={(recipe: Recipe) => {
              setRecipeToDelete(recipe);
              setDeleteRecipeModalVisible(true);
            }}
            onReordered={reorderRecipes}
          />
        )}
        {hasUnsavedChanges && (
          <ContextualSaveBar
            message="You have unsaved changes."
            alignContentFlush
            fullWidth
            saveAction={{
              onAction: saveRecipeOrder,
              loading: saveRecipeOrderLoading,
              disabled: false,
            }}
            discardAction={{
              onAction: () => {
                setReorderedRecipes(recipes || []);
                setHasUnsavedChanges(false);
                setFilteredRecipes(recipes ? [...recipes] : []);
              },
            }}
          />
        )}
        {recipeToDelete && (
          <DeleteRecipeModal
            recipe={recipeToDelete}
            active={deleteRecipeModalVisible}
            setActive={setDeleteRecipeModalVisible}
            onDeleted={reloadRecipeData}
          />
        )}
      </Card>
    </div>
  );
}
