// Definition of connection options we support
import React from 'react';

import KlaviyoConnection from './connections/KlaviyoConnection';
import type { ConnectionImplementationProps } from './types';

export function getConnections(
  args: ConnectionImplementationProps,
): [string, React.ReactNode][] {
  return [
    [
      'klaviyo',
      <KlaviyoConnection
        key="klaviyo"
        integrations={args.integrations}
        reloadIntegrations={args.reloadIntegrations}
      />,
    ],
  ];
}
