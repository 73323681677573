import React from 'react';

import * as Sentry from '@sentry/react';
import { AppProvider } from '@shopify/polaris';
import type { LinkLikeComponentProps } from '@shopify/polaris/build/ts/latest/src/utilities/link';
import enTranslations from '@shopify/polaris/locales/en.json';
import ReactDOM from 'react-dom';
import './index.css';
import {
  BrowserRouter,
  createRoutesFromChildren,
  Link as RouterLink,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import '@shopify/app-bridge';

import App from './App';

const IS_EXTERNAL_LINK_REGEX = /^(?:[a-z][a-z\d+.-]*:|\/\/)/;

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,

  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new Sentry.Replay({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
    }),
  ],

  // Set sample rates to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // This may need to be adjusted when we have more traffic, but for now let's
  // capture everything.
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,

  enabled:
    process.env.NODE_ENV !== 'development' ||
    process.env.REACT_APP_SENTRY_ENABLED === 'true',
});

function Link({
  children,
  url = '',
  external,
  ref,
  ...rest
}: LinkLikeComponentProps) {
  if (external || IS_EXTERNAL_LINK_REGEX.test(url)) {
    return (
      <a href={url} target="_blank" rel="noopener noreferrer" {...rest}>
        {children}
      </a>
    );
  }

  return (
    <RouterLink to={url} ref={ref as React.Ref<HTMLAnchorElement>} {...rest}>
      {children}
    </RouterLink>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <AppProvider i18n={enTranslations} linkComponent={Link}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AppProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
