import { KeyboardEvent } from 'react';

import { SelectGroup } from '@shopify/polaris/build/ts/latest/src/components/Select/Select';

import { ShopifyAsset } from '../api';

export const capitalizeFirstLetter = (text: string): string => {
  try {
    return text.charAt(0).toUpperCase() + text.slice(1);
  } catch (e) {
    return text;
  }
};

export const colorConvertors = {
  /**
   * Converts a hexadecimal color code to an RGBA object
   * @param {string} hexValue - The hexadecimal color code to convert. (#rrggbb or #rrggbbaa)
   * @returns {object} - The RGBA object with properties for red, green, blue, and alpha values.
   * @throws {Error} - If the hexValue is invalid or does not match the expected format.
   */
  convertHexToRgbaObj: (hexValue: string) => {
    const hex = hexValue.match(
      /^#([a-f0-9]{3}|[a-f0-9]{6}|[a-f0-9]{8})$/i,
    )?.[1];
    if (!hex) {
      throw new Error(`Invalid hex color value. Expecting:
      Hexadecimal color code with alpha (#RRGGBBAA) ex: (#FF000080) or
      Hexadecimal color code without alpha (#RRGGBB): ex: (#FF0000) or
      Hexadecimal short code without alpha (#RGB): ex: (#F00)`);
    }
    const hexR = hex.length === 3 ? hex[0] + hex[0] : hex.substring(0, 2);
    const hexG = hex.length === 3 ? hex[1] + hex[1] : hex.substring(2, 4);
    const hexB = hex.length === 3 ? hex[2] + hex[2] : hex.substring(4, 6);
    const hexA = hex.length === 8 ? hex.substring(6, 8) : 'FF';

    const r = parseInt(hexR, 16);
    const g = parseInt(hexG, 16);
    const b = parseInt(hexB, 16);
    const a = parseInt(hexA, 16) / 255;
    return { r, g, b, a };
  },

  /**
   * Converts a hexadecimal color code to an RGBA color string with the specified alpha value.
   *
   * @param {string} hexValue - The hexadecimal color code to convert. (#rrggbb)
   * @param {number} alpha - The alpha value (opacity) to apply to the RGBA color. Should be between 0 and 1.
   * @returns {string} - The RGBA color string in the format "rgba(R, G, B, A)".
   * @throws {Error} - If the hexValue is invalid or does not match the expected format.
   */
  hexToRgba: (hexValue: string, alpha: number) => {
    const { r, g, b } = colorConvertors.convertHexToRgbaObj(hexValue);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  },

  /**
   * Converts a hexadecimal color code with alpha channel to an RGBA color string.
   *
   * @param {string} hexValue - The hexadecimal color code to convert, including alpha channel. (#rrggbbaa)
   * @returns {string} - The RGBA color string in the format "rgba(R, G, B, A)".
   * @throws {Error} - If the hexValue is invalid or does not match the expected format.
   */
  hex8ToRgba: (hexValue: string) => {
    const { r, g, b, a } = colorConvertors.convertHexToRgbaObj(hexValue);
    return `rgba(${r}, ${g}, ${b}, ${a})`;
  },

  /**
   * Converts an RGBA color string or #RRGGBBAA color string to an RGBA object.
   *
   * @param {string} rgba - The RGBA color string or the #RRGGBBAA color string to convert .
   * @returns {object} - The RGBA object with properties for red, green, blue, and alpha values.
   * @throws {Error} - If the RGBA color value is invalid or does not match the expected format.
   */
  rgbaStringObj: (rgba: string | null) => {
    if (rgba == null) return { r: 0, g: 0, b: 0, a: 1 };
    let newRgba = rgba;
    const hexRegex = /^#[a-f0-9]{3,8}$/i;
    if (rgba.match(hexRegex)) {
      newRgba = colorConvertors.hex8ToRgba(rgba);
    }
    const matches = newRgba.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+),\s*(\d*(?:\.\d+)?)\)$/,
    );
    if (!matches) {
      throw new Error(`Invalid RGBA color value.`);
    }
    const r = parseInt(matches[1], 10);
    const g = parseInt(matches[2], 10);
    const b = parseInt(matches[3], 10);
    const a = parseFloat(matches[4]);
    return { r, g, b, a };
  },

  /**
   * Converts RGB values to a hexadecimal color code.
   *
   * @param {number} r - The red value (0-255).
   * @param {number} g - The green value (0-255).
   * @param {number} b - The blue value (0-255).
   * @returns {string} - The hexadecimal color code in the format "#RRGGBB".
   */
  rgbToHex: (r: number, g: number, b: number) => {
    const hexR = r.toString(16).padStart(2, '0');
    const hexG = g.toString(16).padStart(2, '0');
    const hexB = b.toString(16).padStart(2, '0');
    return `#${hexR}${hexG}${hexB}`;
  },
};

export const handleKeyPress = (
  event: KeyboardEvent<HTMLDivElement>,
  callback: any,
) => {
  const enterKeyPressed = event.keyCode === 13;
  if (enterKeyPressed) {
    event.preventDefault();
    callback();
  }
};

export const cleanTemplate = (templateName: string): string =>
  templateName
    .replace('templates/', '')
    .replace('sections/', '')
    .replace('-group.json', '')
    .replace('.json', '')
    .replace('page.', '')
    .replace('index', 'Home page')
    .replace('/', ' ')
    .replace('_', ' ');

export const initialTemplates = (assetList: ShopifyAsset[]): SelectGroup[] => {
  const templates: SelectGroup[] = [];

  const GROUP_1_MATCHERS = [
    'sections/header-group.json',
    'sections/footer-group.json',
  ];

  const mapTemplates = (template: ShopifyAsset) => ({
    label: capitalizeFirstLetter(cleanTemplate(template.key)),
    value: template.key,
  });

  if (assetList) {
    const commonList = assetList
      .filter(template => GROUP_1_MATCHERS.includes(template.key))
      .map(mapTemplates);
    const pagesList = assetList
      .filter(template => !GROUP_1_MATCHERS.includes(template.key))
      .map(mapTemplates);
    templates.push({
      title: 'Common',
      options: commonList,
    });
    templates.push({
      title: 'Pages',
      options: pagesList,
    });
  }

  return templates;
};

export const isNgrokUrl = (url = window.location.href): boolean =>
  url.match('https://(.*).ngrok.io') !== null;

export interface NavigateToVariationProps {
  readonly encodedVariationId: string;
  readonly sectionId: string;
  readonly pathname: string;
}

export const isEmptyObject = (obj: {}) => Object.keys(obj).length === 0;

export const convertTime12to24 = (time12h: string) => {
  const [time, modifier] = time12h.split(' ');
  const [h, minutes] = time.split(':');
  let hours = h;
  if (hours === '12') {
    hours = '00';
  }
  if (modifier === 'pm') {
    hours = (parseInt(hours, 10) + 12).toString();
  }
  return `${hours}:${minutes}`;
};

export const convertTime24to12 = (time24h: string) => {
  const [h, minutes] = time24h.split(':');
  let hours = h;
  const modifier = parseInt(hours, 10) >= 12 ? 'pm' : 'am';

  if (hours === '00') {
    hours = '12';
  }

  if (modifier === 'pm' && parseInt(hours, 10) !== 12) {
    hours = (parseInt(hours, 10) - 12).toString();
  }

  hours = hours.padStart(2, '0');

  return `${hours}:${minutes} ${modifier}`;
};

export const removeMarkdown = (text: string | null | undefined) => {
  if (text == null) return '';
  const boldRegex = /\*\*(.*?)\*\*/g;
  const italicRegex = /\*(.*?)\*/g;
  const linkRegex = /\[(.*?)\]\((.*?)\)/g;

  return text
    .replace(boldRegex, '$1')
    .replace(italicRegex, '$1')
    .replace(linkRegex, '');
};
