import React, { useState, useCallback } from 'react';

import { Modal, FormLayout, TextField, InlineError } from '@shopify/polaris';

import { useFetchJson, ApiError } from '../../api';
import { handleKeyPress } from '../../common/utils';

type RenameRecipeModalProps = {
  recipe: Recipe;
  active: boolean;
  setActive: (active: boolean) => void;
  onRenamed: (error?: Error) => void;
};

export default function RenameRecipeModal({
  recipe,
  active,
  setActive,
  onRenamed,
}: RenameRecipeModalProps): JSX.Element {
  const [name, setName] = useState(recipe.name);
  const [description, setDescription] = useState(recipe.desc);
  const jsonFetch = useFetchJson();
  const [formError, setFormError] = useState(undefined as ApiError | undefined);
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(() => {
    async function submit() {
      setLoading(true);

      try {
        await jsonFetch(
          'post',
          `/recipes/${encodeURIComponent(recipe.id)}/rename`,
          {
            name,
            desc: description,
          },
        );
        onRenamed();
      } catch (err) {
        if (err instanceof ApiError) {
          setFormError(err);
        } else {
          onRenamed(err as Error);
        }
      }

      setLoading(false);
      setActive(false);
    }

    submit();
  }, [setActive, recipe, jsonFetch, onRenamed, setLoading, name, description]);

  return (
    <Modal
      open={active}
      onClose={() => setActive(false)}
      title="Update recipe"
      primaryAction={{
        content: 'Update recipe',
        onAction: handleSubmit,
        loading,
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: () => setActive(false),
        },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          <div
            onKeyDown={event => handleKeyPress(event, handleSubmit)}
            role="button"
            tabIndex={0}
          >
            <TextField
              autoComplete="off"
              value={name}
              onChange={setName}
              label="Recipe name"
              type="text"
              placeholder="Enter a unique recipe name"
              requiredIndicator
              helpText={<span>e.g. Running gear for rainy days</span>}
            />
          </div>
          <TextField
            autoComplete="off"
            value={description}
            onChange={setDescription}
            label="Recipe description"
            type="text"
            placeholder="Enter a description (optional)"
            multiline={4}
          />
          {formError && formError.message === 'invalid_name' && (
            <InlineError message="Invalid recipe name." fieldID="name" />
          )}
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}
