import { useState, createContext, useContext, useMemo } from 'react';

const ONE_DAY = 1000 * 60 * 60 * 24;
const ONE_WEEK = ONE_DAY * 7;

export type DateRange = {
  start: Date;
  end: Date;
};

type AnalyticsContextType = {
  selectedDates: DateRange;
  setSelectedDates: (newRange: DateRange) => void;
};

const AnalyticsContext = createContext<AnalyticsContextType>({
  selectedDates: {
    start: new Date(Date.now() - ONE_DAY - ONE_WEEK),
    end: new Date(Date.now() - ONE_DAY),
  },
  setSelectedDates: () => {},
});

type AnalyticsProviderProps = {
  children: React.ReactNode;
};

export function AnalyticsProvider({ children }: AnalyticsProviderProps) {
  const [selectedDates, setSelectedDates] = useState<DateRange>({
    start: new Date(Date.now() - ONE_DAY - ONE_WEEK),
    end: new Date(Date.now() - ONE_DAY),
  });

  const providerValue = useMemo(
    () => ({
      selectedDates,
      setSelectedDates,
    }),
    [selectedDates],
  );

  return (
    <AnalyticsContext.Provider value={providerValue}>
      {children}
    </AnalyticsContext.Provider>
  );
}

export function useAnalyticsContext() {
  return useContext(AnalyticsContext);
}
