import { useCallback, useEffect, useState } from 'react';

import { Button, InlineError, TextField } from '@shopify/polaris';
import { CancelSmallMinor, EditMinor, TickMinor } from '@shopify/polaris-icons';

import { useFetchJson } from '../../api';

type SectionNameProps = {
  name: string;
  id: string;
  reloadSections: () => Promise<void>;
};

export function SectionName({
  name,
  id,
  reloadSections,
}: SectionNameProps): JSX.Element {
  const fetch = useFetchJson();
  const [editMode, setEditMode] = useState(false);
  const [updatedName, setUpdatedName] = useState(name);
  const [inputError, setInputError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setUpdatedName(name);
  }, [name]);

  const handleNameChange = useCallback((value: string) => {
    setInputError(false);
    setUpdatedName(value);
  }, []);

  const handleNameSubmit = useCallback(async () => {
    if (updatedName === name) {
      setEditMode(false);
      return;
    }

    if (!updatedName) {
      setInputError(true);
      return;
    }

    setLoading(true);

    await fetch('post', `/shopify/sections/${id}`, { name: updatedName });

    setLoading(false);
    setEditMode(false);

    await reloadSections();
  }, [updatedName, name, id, fetch, reloadSections]);

  const handleCancel = useCallback(() => {
    setUpdatedName(name);
    setEditMode(false);
    setInputError(false);
  }, [name]);

  return editMode ? (
    <div className="rb-flex rb-items-center rb-gap-1">
      <TextField
        label="New name"
        id="nameField"
        labelHidden
        autoComplete="off"
        placeholder="Hero Banner"
        value={updatedName}
        onChange={handleNameChange}
        clearButton
        onClearButtonClick={() => setUpdatedName('')}
        error={inputError}
      />
      <Button icon={TickMinor} onClick={handleNameSubmit} loading={loading} />
      <Button
        icon={CancelSmallMinor}
        onClick={handleCancel}
        disabled={loading}
      />
      <InlineError
        fieldID="nameField"
        message={inputError ? "Name can't be empty" : ''}
      />
    </div>
  ) : (
    <div className="rb-flex rb-items-center rb-gap-1">
      <div>{updatedName}</div>
      <Button icon={EditMinor} plain onClick={() => setEditMode(true)} />
    </div>
  );
}
