import {
  useClientRouting,
  useRoutePropagation,
  History,
} from '@shopify/app-bridge-react';
import {
  Route,
  Routes as ReactRouterRoutesWrapper,
  Location,
} from 'react-router-dom';

import Layout from './Layout';
import Home from '../pages/Home';
import PageNotFound from '../pages/PageNotFound';
import RecipeDetails from '../pages/RecipeDetails';
import Settings from '../pages/Settings';

interface RoutesProps {
  readonly history: History;
  readonly location: Location;
}

function Routes(props: RoutesProps): JSX.Element {
  const { history, location } = props;

  useClientRouting(history);
  useRoutePropagation(location);

  return (
    <ReactRouterRoutesWrapper>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="settings/*" element={<Settings />} />
        <Route path="recipes/:id/*" element={<RecipeDetails />} />
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </ReactRouterRoutesWrapper>
  );
}

export default Routes;
