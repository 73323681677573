import React from 'react';

type TableHeaderProps = {
  children: React.ReactNode;
};

export default function TableHeader({
  children,
}: TableHeaderProps): JSX.Element {
  return (
    <div className="rb-h-[52px] rb-px-[17px] rb-border-b rb-border-row-border rb-w-full">
      {children}
    </div>
  );
}
