import React from 'react';

import { usePlans, useFetchJson } from '../../api';
import { useShopStatus } from '../../graph';
import FooterDocs from '../FooterDocs';
import SubscriptionGrid from '../SubscriptionGrid';

export default function Subscriptions(): JSX.Element {
  const { isDevelopment, launchUrl, subscription } = useShopStatus();
  const { plans } = usePlans();
  const jsonFetch = useFetchJson();

  const handleChoose = async (chosen: Plan) => {
    let baseUrl;

    if (launchUrl) {
      const hostOrigin = new URL(launchUrl).origin;

      if (hostOrigin !== 'https://admin.shopify.com') {
        const shopName = launchUrl
          .split('.myshopify.com')[0]
          .split('https://')[1];
        const appName = launchUrl.split('/admin/apps/')[1];

        baseUrl = `https://admin.shopify.com/store/${shopName}/apps/${appName}`;
      } else {
        baseUrl = launchUrl;
      }
    }

    const { confirmationUrl } =
      chosen.name === 'Explorer'
        ? await jsonFetch('post', '/shopify/cancel', {
            launchUrl: baseUrl,
            id: subscription?.id,
          })
        : await jsonFetch('post', '/shopify/subscription', {
            launchUrl: baseUrl,
            ...chosen,
          });
    if (typeof confirmationUrl === 'string' && window.top) {
      window.top.location = confirmationUrl;
    }
  };

  return (
    <div>
      <h1 className="rb-text-4xl rb-font-semibold rb-py-1 rb-capitalize rb-mb-[20px]">
        Subscriptions
      </h1>
      <SubscriptionGrid
        plans={plans}
        current={subscription?.name}
        development={!subscription && isDevelopment}
        onChoose={handleChoose}
      />
      <FooterDocs
        text="Sessions match closely to your store traffic."
        linkText="Learn more about sessions."
        link="https://docs.context.relevantbits.com/sessions"
      />
    </div>
  );
}
