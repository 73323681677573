import React, { useState, useCallback } from 'react';

import {
  Button,
  TextField,
  Modal,
  FormLayout,
  InlineError,
} from '@shopify/polaris';
import { useNavigate } from 'react-router-dom';

import { useFetchJson, ApiError } from '../api';
import { handleKeyPress } from '../common/utils';
import Snapshot from '../components/Analytics/Snapshot';
import FilterField from '../components/FilterField';
import PageHeader from '../components/PageHeader';
import RecipeList from '../components/RecipeList';

type CreateRecipeModalProps = {
  active: boolean;
  setActive: (active: boolean) => void;
};

function CreateRecipeModal({
  active,
  setActive,
}: CreateRecipeModalProps): JSX.Element {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const navigate = useNavigate();
  const fetchJson = useFetchJson();
  const [formError, setFormError] = useState(undefined as ApiError | undefined);
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(() => {
    async function submit() {
      setLoading(true);

      try {
        const recipe = await fetchJson('put', '/recipes', {
          name,
          desc: description,
        });
        navigate(`/recipes/${encodeURIComponent(recipe.id as string)}/rules`);
      } catch (err) {
        setLoading(false);

        if (err instanceof ApiError) {
          setFormError(err);
          throw err;
        } else {
          throw new Error("can't create recipe");
        }
      }
    }

    submit();
  }, [fetchJson, name, description, setFormError, navigate]);

  return (
    <Modal
      open={active}
      onClose={() => setActive(false)}
      title="Create recipe"
      primaryAction={{
        content: 'Create recipe',
        onAction: handleSubmit,
        loading,
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: () => setActive(false),
        },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          <div
            onKeyDown={event => handleKeyPress(event, handleSubmit)}
            role="button"
            tabIndex={0}
          >
            <TextField
              autoComplete="off"
              value={name}
              onChange={setName}
              label="Recipe name"
              type="text"
              placeholder="Enter a unique recipe name"
              requiredIndicator
              helpText={<span>e.g. Running gear for rainy days</span>}
            />
          </div>
          <TextField
            autoComplete="off"
            value={description}
            onChange={setDescription}
            label="Recipe description"
            type="text"
            placeholder="Enter a description (optional)"
            multiline={4}
          />
          {formError && formError.message === 'invalid_name' && (
            <InlineError message="Invalid recipe name." fieldID="name" />
          )}
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}

export default function Home(): JSX.Element {
  const [createRecipeModalVisible, setCreateRecipeModalVisible] =
    useState(false);

  const [filter, setFilter] = useState('');

  return (
    <>
      <div className="rb-pt-7.5 rb-pb-1">
        <Snapshot />
        <PageHeader
          title="My Recipes"
          leftChildren={
            <FilterField
              queryValue={filter}
              setFilter={setFilter}
              queryPlaceholder="Find"
            />
          }
        >
          <Button primary onClick={() => setCreateRecipeModalVisible(true)}>
            Create recipe
          </Button>
        </PageHeader>
      </div>
      <div className="rb-px-11.5 rb-mt-2">
        <RecipeList
          createRecipe={() => setCreateRecipeModalVisible(true)}
          filter={filter}
        />
      </div>
      <CreateRecipeModal
        active={createRecipeModalVisible}
        setActive={setCreateRecipeModalVisible}
      />
    </>
  );
}
