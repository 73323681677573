import React, { ChangeEvent } from 'react';

import { convertTime12to24, convertTime24to12 } from '../../common/utils';

interface TimePickerProps {
  time: string;
  setTime: (time: string) => void;
}

export default function TimePicker({
  time = '00:00',
  setTime,
}: TimePickerProps) {
  const time12h = convertTime24to12(time);
  const [timeWithoutModifier, modifier] = time12h.split(' ');
  const [hours, minutes] = timeWithoutModifier.split(':');

  const handleHourChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const numericInput = value.replace(/[^0-9]/g, '');

    let paddedValue = numericInput.padStart(2, '0');
    if (Number(paddedValue) > 12 || Number(paddedValue) < 1) {
      return;
    }
    if (paddedValue.length === 3 && paddedValue[0] === '0') {
      paddedValue = paddedValue.slice(1);
    }
    const time24h = convertTime12to24(`${paddedValue}:${minutes} ${modifier}`);
    setTime(time24h);
  };

  const handleMinuteChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const numericInput = value.replace(/[^0-9]/g, '');

    let paddedValue = numericInput.padStart(2, '0');
    if (Number(paddedValue) > 59 || Number(paddedValue) < 0) {
      return;
    }
    if (paddedValue.length === 3 && paddedValue[0] === '0') {
      paddedValue = paddedValue.slice(1);
    }
    const time24h = convertTime12to24(`${hours}:${paddedValue} ${modifier}`);
    setTime(time24h);
  };

  const handleModifierChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    const time24h = convertTime12to24(`${hours}:${minutes} ${value}`);
    setTime(time24h);
  };

  return (
    <div className="rb-bg-white-bg rb-border rb-border-date-border rb-rounded-lg rb-overflow-hidden rb-py-1 rb-px-2">
      <input
        type="text"
        value={hours}
        onChange={handleHourChange}
        className="rb-w-[18px] rb-text-center rb-outline-none"
      />
      <span>:</span>
      <input
        type="text"
        value={minutes}
        onChange={handleMinuteChange}
        className="rb-w-[18px] rb-text-center rb-outline-none"
      />
      <select
        name="modifier"
        id="modifier"
        onChange={handleModifierChange}
        className="rb-outline-none"
        value={modifier}
      >
        <option value="am">am</option>
        <option value="pm">pm</option>
      </select>
    </div>
  );
}
