import React, { useState, JSX, useEffect, useCallback } from 'react';

import { Select } from '@shopify/polaris';
import { SelectGroup } from '@shopify/polaris/build/ts/latest/src/components/Select/Select';

import { LoadingSection } from './LoadingSection';
import TemplateSections from './TemplateSections';
import { useSections, useVariations } from '../../api';
import FilterField from '../FilterField';
import VintageTheme from '../VintageTheme';

type Props = {
  recipeData: Recipe;
  setRecipeData: (recipeData: Recipe) => void;
  templates: SelectGroup[];
  currentTemplate: string;
  setCurrentTemplate: (newTemplate: string) => void;
};

export default function RecipeVariations({
  recipeData,
  setRecipeData,
  templates,
  currentTemplate,
  setCurrentTemplate,
}: Props): JSX.Element {
  const [selectedVariations, setSelectedVariations] = useState(
    {} as Record<string, string[]>,
  );
  const [filter, setFilter] = useState<string>('');
  const {
    sections,
    reload: reloadSections,
    error: sectionsError,
  } = useSections(currentTemplate);
  const { variations, reload: updateVariations } = useVariations();

  useEffect(
    () => () => {
      setSelectedVariations({});
      setFilter('');
    },
    [],
  );

  useEffect(() => {
    setSelectedVariations(recipeData.variations || []);
  }, [recipeData, setSelectedVariations]);

  const updateSections = useCallback(
    ({ nextRecipeData, sectionId, variantId, checked }): void => {
      const selected = nextRecipeData.variations[sectionId] ?? [];
      const index = selected.indexOf(variantId);
      if (checked) {
        selected.push(variantId);
      } else {
        selected.splice(index, 1);
      }

      // take opportunity to clean up deleted variations per CTX-1167
      const variationIds = new Set(variations.map(variation => variation.id));
      const cleanSelected = selected.filter((v: string) => variationIds.has(v));

      setRecipeData({
        ...nextRecipeData,
        variations: {
          ...nextRecipeData.variations,
          [sectionId]: cleanSelected,
        },
      });
    },
    [setRecipeData, variations],
  );

  const handleTemplateSelectChange = useCallback(
    (selected: string) => {
      setCurrentTemplate(selected);
    },
    [setCurrentTemplate],
  );

  if (
    templates &&
    (templates.length === 0 ||
      (sections && sections.length === 0) ||
      sectionsError?.statusCode === 404)
  ) {
    return <VintageTheme />;
  }

  return (
    <div className="rb-min-h-4/5 rb-flex rb-flex-col">
      {templates && templates.length > 0 ? (
        <>
          <div className="rb-flex rb-flex-row rb-items-center rb-mb-2">
            <div>
              <Select
                label="Template"
                labelHidden
                options={templates}
                onChange={handleTemplateSelectChange}
                value={currentTemplate}
              />
            </div>
            <div className="rb-flex rb-flex-row rb-ml-auto">
              <div className="rb-mr-2">
                <FilterField
                  queryValue={filter}
                  setFilter={setFilter}
                  queryPlaceholder="Find"
                />
              </div>
            </div>
          </div>
          <div className="rb-flex rb-pt-2 rb-flex-grow rb-select-none">
            <TemplateSections
              updateSections={updateSections}
              sections={sections}
              reloadSections={reloadSections}
              variations={variations}
              updateVariations={updateVariations}
              selectedVariations={selectedVariations}
              recipeData={recipeData}
              filter={filter}
            />
          </div>
        </>
      ) : (
        LoadingSection('templates')
      )}
    </div>
  );
}
