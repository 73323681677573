import { useMemo } from 'react';

import { SkeletonDisplayText } from '@shopify/polaris';

import { Section, Variation, VariationAnalyticsComparison } from '../../../api';
import Table from '../../Table';
import TableHeader from '../../Table/TableHeader';
import TableRow from '../../Table/TableRow';
import PercentChangeIndicator from '../PercentChangeIndicator';

const TABLE_HEADER = (
  <TableHeader>
    <div className="rb-flex rb-w-full rb-h-full rb-items-center rb-gap-2">
      <Column flex={1} bold>
        Section
      </Column>
      <Column flex={2} bold>
        Variation
      </Column>
      <Column textAlign="center" bold>
        Impressions
      </Column>
      <Column textAlign="center" bold>
        Engaged
      </Column>
      <Column textAlign="center" bold>
        UPT
      </Column>
      <Column textAlign="center" bold>
        AOV
      </Column>
      <Column textAlign="center" bold>
        CVR
      </Column>
    </div>
  </TableHeader>
);

const SKELETON_ROW = (
  <TableRow>
    <div className="rb-flex rb-w-full rb-h-full rb-items-center rb-gap-2">
      <Column flex={1}>
        <SkeletonDisplayText size="small" />
      </Column>
      <Column flex={2}>
        <SkeletonDisplayText size="small" />
      </Column>
      <SkeletonDataColumn />
      <SkeletonDataColumn />
      <SkeletonDataColumn />
      <SkeletonDataColumn />
      <SkeletonDataColumn />
    </div>
  </TableRow>
);

type RecipeAnalyticsTableProps = {
  data: VariationAnalyticsComparison;
  recipeData: Recipe;
  sections: Section[] | undefined;
  variations: Variation[] | undefined;
};

export default function VariationAnalyticsTable({
  data,
  recipeData,
  sections,
  variations,
}: RecipeAnalyticsTableProps): JSX.Element {
  const rows = useMemo(() => {
    if (!data || !sections || !variations) {
      return [];
    }

    const variationNames: Record<string, string> = {};
    variations.forEach(variation => {
      variationNames[variation.id] = variation.name;
    });

    const rowBuffer: JSX.Element[] = [];

    sections.forEach(section => {
      const variationList = recipeData.variations[section.id];
      if (!variationList) return;
      variationList.forEach(variation => {
        // Mixpanel is recording variations in lowercase without the varation# prefix
        const variationId = (
          variation.split('#')[1] || variation
        ).toLowerCase();
        rowBuffer.push(
          <TableRow key={variation}>
            <div className="rb-flex rb-w-full rb-h-full rb-items-center rb-gap-2">
              <Column flex={1}>{section.title}</Column>
              <Column flex={2}>{variationNames[variation]}</Column>
              <DataColumn
                value={data.current.variations[variationId]?.impressions || 0}
                compare={
                  data.previous.variations[variationId]?.impressions || 0
                }
              />
              <DataColumn
                value={
                  Math.round(
                    10 ** 4 *
                      (data.current.variations[variationId]?.engaged || 0),
                  ) /
                  10 ** 2
                }
                compare={
                  Math.round(
                    10 ** 4 *
                      (data.previous.variations[variationId]?.engaged || 0),
                  ) /
                  10 ** 2
                }
                percent
              />
              <DataColumn
                value={data.current.variations[variationId]?.upt || 0}
                compare={data.previous.variations[variationId]?.upt || 0}
              />
              <DataColumn
                value={data.current.variations[variationId]?.aov || 0}
                compare={data.previous.variations[variationId]?.aov || 0}
              />
              <DataColumn
                value={
                  Math.round(
                    10 ** 4 * (data.current.variations[variationId]?.cvr || 0),
                  ) /
                  10 ** 2
                }
                compare={
                  Math.round(
                    10 ** 4 * (data.previous.variations[variationId]?.cvr || 0),
                  ) /
                  10 ** 2
                }
                percent
              />
            </div>
          </TableRow>,
        );
      });
    });

    return rowBuffer;
  }, [recipeData, data, sections, variations]);

  if (!data || !sections || !variations) {
    return (
      <Table header={TABLE_HEADER} background="white">
        {SKELETON_ROW}
      </Table>
    );
  }

  return (
    <Table header={TABLE_HEADER} background="white">
      {rows}
    </Table>
  );
}

type ColumnTitleProps = {
  children: React.ReactNode;
  flex?: number;
  textAlign?: 'left' | 'center' | 'right';
  bold?: boolean;
};

Column.defaultProps = {
  flex: 0,
  textAlign: 'left',
  bold: false,
};

function Column({
  children,
  flex,
  textAlign,
  bold,
}: ColumnTitleProps): JSX.Element {
  const dynamicStyles = {
    flexGrow: flex,
  };
  return (
    <div
      style={dynamicStyles}
      className={`rb-h-min rb-basis-[150px] rb-shrink rb-text-${textAlign} ${
        bold ? 'rb-font-semibold' : 'rb-font-normal'
      }`}
    >
      {children}
    </div>
  );
}

type DataColumnProps = {
  value: number;
  compare: number;
  percent?: boolean;
};

DataColumn.defaultProps = {
  percent: false,
};

function DataColumn({ value, compare, percent }: DataColumnProps): JSX.Element {
  return (
    <Column>
      <div className="rb-w-full rb-flex rb-justify-center rb-gap-1">
        <div className="rb-flex-1 rb-flex rb-justify-end rb-items-center">
          {value}
          {percent ? '%' : ''}
        </div>
        <div className="rb-flex-1 rb-flex rb-justify-start rb-items-center">
          <PercentChangeIndicator value={value} compare={compare} inline />
        </div>
      </div>
    </Column>
  );
}

function SkeletonDataColumn(): JSX.Element {
  return (
    <Column>
      <div className="rb-w-full rb-flex rb-justify-center rb-gap-1">
        <div className="rb-w-full">
          <SkeletonDisplayText size="small" />
        </div>
      </div>
    </Column>
  );
}
