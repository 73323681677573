import React from 'react';

import { FormLayout, Select, TextField } from '@shopify/polaris';

function Component({ data, setData }: RecipeRuleProps): JSX.Element {
  const { fieldName, condition, value } = data;

  return (
    <FormLayout>
      <FormLayout.Group condensed>
        <Select
          label=""
          options={[
            { label: 'campaign field', value: 'campaign' },
            { label: 'content field', value: 'content' },
            { label: 'medium field', value: 'medium' },
            { label: 'source field', value: 'source' },
            { label: 'term field', value: 'term' },
          ]}
          onChange={(nextFieldName: string) =>
            setData((nextData: RecipeRuleData) => ({
              ...nextData,
              fieldName: nextFieldName,
            }))
          }
          value={fieldName}
        />
        <Select
          label=""
          options={[
            { label: 'exactly matches', value: 'exactlyMatches' },
            { label: 'starts with', value: 'startsWith' },
          ]}
          onChange={(nextCondition: string) =>
            setData((nextData: RecipeRuleData) => ({
              ...nextData,
              condition: nextCondition,
            }))
          }
          value={condition}
        />
        <TextField
          autoComplete="off"
          label=""
          onChange={(nextValue: string) =>
            setData((nextData: RecipeRuleData) => ({
              ...nextData,
              value: nextValue,
            }))
          }
          value={value}
          placeholder="parameter value"
        />
      </FormLayout.Group>
    </FormLayout>
  );
}

export default class UTMParametersRule implements RecipeRule {
  id = '';

  name = 'UTM parameter';

  helpCenterArticle = 'utm-parameters';

  schema = {
    fieldName: 'campaign',
    condition: 'exactlyMatches',
    value: '',
  };

  render = (
    data: RecipeRuleData,
    setData: RecipeRuleDataSetter,
  ): JSX.Element => <Component data={data} setData={setData} />;
}
