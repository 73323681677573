import { Link } from '@shopify/polaris';

import { ReactComponent as ErrorSVG } from './error.svg';
import { ReactComponent as PatternSVG } from './pattern.svg';
import styles from './styles.module.css';

export default function PageNotFound(): JSX.Element {
  return (
    <div className={styles.boundary}>
      <div className={styles.errorContent}>
        <div className={styles.errorMessage}>
          <PatternSVG />
          <h1 className={styles.errorHeading}>Whoops!</h1>
          <p>
            It looks like the page you&apos;re looking for doesn&apos;t exist.
          </p>
          <Link url="/" removeUnderline>
            <button type="button" className={styles.button__primary}>
              Go home
            </button>
          </Link>
        </div>
        <div style={{ position: 'relative', right: '10%', zIndex: 0 }}>
          <ErrorSVG />
        </div>
      </div>
    </div>
  );
}
